<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :store-module-name="STORE_MODULE_NAME"
          :refetch-events="refetchEvents"
          :add-new="addNew"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="{...calendarOptions, resources: roomResources}"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        :store-module-name="STORE_MODULE_NAME"
        :store-module-name-member="STORE_MODULE_NAME_MEMBER"
        :store-module-name-course-package="STORE_MODULE_NAME_COURSE_PACKAGE"
        :store-module-name-room="STORE_MODULE_NAME_ROOM"
        :event-data="event"
        :refetch-events="refetchEvents"
        :remove-event-in-calendar="removeEventInCalendar"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
// eslint-disable-next-line import/extensions
import storeModule from '@/store/services/eCommerce/reservationBooking'
import storeModuleMember from '@/store/services/eCommerce/member'
import storeModuleCoursePackage from '@/store/services/eCommerce/reservationCoursePackage'
import storeModuleRoom from '@/store/services/eCommerce/reservationRoom'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import thLocale from '@fullcalendar/core/locales/th'
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
// import useCalendar from './useCalendar'

const STORE_MODULE_NAME = 'reservationBooking'
const STORE_MODULE_NAME_MEMBER = 'member'
const STORE_MODULE_NAME_COURSE_PACKAGE = 'reservationCoursePackage'
const STORE_MODULE_NAME_ROOM = 'reservationRoom'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  data() {
    return {
      event: {},
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, resourceTimeGridPlugin],
        initialView: 'resourceTimeGridDay',
        resourceLabelContent(arg) {
          // arg.resource มี property extendedProps หากต้องการเก็บ url รูป
          // เช่น arg.resource.title = 'Room A'
          //     arg.resource.extendedProps.imgUrl = 'https://example.com/image.jpg'

          const { image } = arg.resource.extendedProps || {}
          const roomName = arg.resource.title

          // ส่งออกเป็น HTML string หรือ object ได้
          return {
            html: `
        <div style="display: flex; align-items: center;">
          <img 
            src="${image}" 
            alt="Room Image" 
            style="width: 24px; height: 24px; margin-right: 8px; border-radius: 4px; border-radius: 50%;"
          />
          <span>${roomName}</span>
        </div>
      `,
          }
        },
        locale: this.$i18n.locale === 'th' ? thLocale : null,
        // headerToolbar: {
        //   start: 'sidebarToggle, prev,next, title',
        //   end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        // },
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,resourceTimeGridDay,resourceTimeGridWeek,listMonth', // ตัวอย่าง
        },
        events: this.fetchEvents,
        editable: true, // ถ้าจะแก้ไขให้ true
        eventResizableFromStart: false, // ถ้าจะแก้ไขให้ true
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        dayMaxEventRows: false,
        // eslint-disable-next-line no-unused-vars
        eventClassNames({ event: calendarEvent }) {
          // eslint-disable-next-line no-underscore-dangle
          const index = store.state[
            STORE_MODULE_NAME
          ].calendarOptions.findIndex(p => (calendarEvent._def.extendedProps.roomId !== null
            ? p._id.toString()
                  === calendarEvent._def.extendedProps.roomId.toString()
            : false))
          if (index > -1) {
            const { colorName } = store.state[
              STORE_MODULE_NAME
            ].calendarOptions[index]
            return [
              // Background Color
              `bg-light-${colorName}`,
            ]
          }
          // const colorName = 'success'
          return [
            // Background Color
            'bg-grey',
          ]
        },
        eventClick: this.eventClick,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          // second: '2-digit',
          hour12: false,
        },
        customButtons: {
          sidebarToggle: {
            // --- This dummy text actual icon rendering is handled using SCSS ----- //
            text: 'sidebar',
            click: this.sidebarToggleClick,
          },
        },
        dateClick: this.dateClick,
        eventDrop: this.eventDrop,
        eventResize: this.eventResize,
        datesSet: this.handleDatesSet, // ใช้ event datesSet
        direction: store.state.appConfig.isRTL ? 'rtl' : 'ltr',
        rerenderDelay: 350,
      },
    }
  },
  computed: {
    calendarApi() {
      return this.$refs.refCalendar.getApi() || null
    },
    selectedCalendars() {
      return store.state[STORE_MODULE_NAME].selectedCalendars
    },
    roomResources() {
      return store.state[
        STORE_MODULE_NAME
      ].calendarOptions.map(r => ({
        id: r._id,
        title: this.showFromCurrentLanguage(r.name),
        extendedProps: {
          image: r.image,
        },
      })).filter(e => this.selectedCalendars.includes(e.id))
    },
  },
  setup() {
    return {
      STORE_MODULE_NAME, STORE_MODULE_NAME_MEMBER, STORE_MODULE_NAME_COURSE_PACKAGE, STORE_MODULE_NAME_ROOM,
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER)) store.registerModule(STORE_MODULE_NAME_MEMBER, storeModuleMember)
    if (!store.hasModule(STORE_MODULE_NAME_COURSE_PACKAGE)) store.registerModule(STORE_MODULE_NAME_COURSE_PACKAGE, storeModuleCoursePackage)
    if (!store.hasModule(STORE_MODULE_NAME_ROOM)) store.registerModule(STORE_MODULE_NAME_ROOM, storeModuleRoom)
    this.getMember()
    this.getCoursePackage()
    this.getRoom()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_MEMBER)) store.unregisterModule(STORE_MODULE_NAME_MEMBER)
    if (store.hasModule(STORE_MODULE_NAME_COURSE_PACKAGE)) store.unregisterModule(STORE_MODULE_NAME_COURSE_PACKAGE)
    if (store.hasModule(STORE_MODULE_NAME_ROOM)) store.unregisterModule(STORE_MODULE_NAME_ROOM)
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    getRoom() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_ROOM}/get`, obj)
        .then(result => {
          this.show = false
          store.commit(`${STORE_MODULE_NAME}/SET_CALENDAR_TYPE`, result.data.data)

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getCoursePackage() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
        type: 'package'
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_COURSE_PACKAGE}/get`, obj)
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getMember() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER}/get`, obj)
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    handleDatesSet(info) {
      console.log('Current view:', info.view.type)
      console.log('Start date:', info.startStr)
      console.log('End date:', info.endStr)
      this.calendarApi.refetchEvents()
    },
    sidebarToggleClick() {
      console.log('sidebarToggleClick!')

      this.isCalendarOverlaySidebarActive = !this
        .isCalendarOverlaySidebarActive
    },
    eventResize(arg) {
      this.updateEvent(this.grabEventDataFromEventApi(arg.event))
      console.log(`event resize! ${arg}`)
    },
    eventDrop(arg) {
      this.updateEvent(this.grabEventDataFromEventApi(arg.event))
    },
    eventClick(arg) {
      console.log(`event click! ${arg}`)
      console.log(arg)

      arg.jsEvent.preventDefault() // jsEvent
      const existingEvent = this.calendarApi.getEventById(arg.event.id)

      this.event = this.grabEventDataFromEventApi(arg.event) // event
      this.isEventHandlerSidebarActive = true
    },
    dateClick(arg) {
      console.log(`date click! ${arg.dateStr}`)
      console.log(arg)
      const data = {
        _id: null,
        id: null,
        title: '',
        start: arg.date,
        end: arg.date,
        allDay: false,
        url: '',
        extendedProps: {
          userId: null,
          userInfo: null,
          roomId: null,
          coursePackageId: null,
          description: '',
          color: '#ffffff',
          show: true,
          paid: false,
          sumPrice: 0,
          roomPrice: 0,
          coursePackagePrice: 0,
          optionPrice: 0,
          options: [],
        },
      }
      console.log(data)

      this.event = this.grabEventDataFromEventApi(data)
      this.isEventHandlerSidebarActive = true
    },
    removeEventInCalendar(eventId) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Removed'),
          icon: 'TrashIcon',
          variant: 'danger',
        },
      })
      this.calendarApi.getEventById(eventId).remove()
    },
    updateEventInCalendar(
      updatedEventData,
      propsToUpdate,
      extendedPropsToUpdate,
    ) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Updated'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const existingEvent = this.calendarApi.getEventById(updatedEventData.id)

      // --- Set event properties except date related ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setProp
      // dateRelatedProps => ['start', 'end', 'allDay']
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < propsToUpdate.length; index++) {
        const propName = propsToUpdate[index]
        existingEvent.setProp(propName, updatedEventData[propName])
      }

      // --- Set date related props ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setDates
      existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
        allDay: updatedEventData.allDay,
      })

      // --- Set event's extendedProps ----- //
      // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < extendedPropsToUpdate.length; index++) {
        const propName = extendedPropsToUpdate[index]
        existingEvent.setExtendedProp(
          propName,
          updatedEventData.extendedProps[propName],
        )
      }
    },
    updateEvent(eventData) {
      store
        .dispatch(`${STORE_MODULE_NAME}/updateEvent`, eventData)
        .then(response => {
          const updatedEvent = response.data

          const propsToUpdate = ['id', 'title', 'url']
          const extendedPropsToUpdate = ['userId', 'userInfo', 'roomId', 'coursePackageId', 'code', 'color', 'description', 'show', 'paid', 'sumPrice', 'roomPrice', 'coursePackagePrice', 'optionPrice', 'options']
          this.updateEventInCalendar(
            updatedEvent,
            propsToUpdate,
            extendedPropsToUpdate,
          )
        })
    },
    refetchEvents() {
      this.calendarApi.refetchEvents()
    },
    fetchEvents(info, successCallback) {
      // If there's no info => Don't make useless API call

      if (!info) return
      // Fetch Events from API endpoint
      // console.log('Current view:', info.view.type)

      store
        .dispatch(`${STORE_MODULE_NAME}/fetchEvents`, {
          startDate: info.startStr,
          endDate: info.endStr,
          roomIds: this.selectedCalendars,
        })
        .then(response => {
          console.log('fetchEvents ', response.data)
          successCallback(
            response.data.map(p => ({
              _id: p._id,
              id: p.id,
              title: `${p.title}`,
              start: p.start,
              end: p.end,
              url: p.url,
              // eslint-disable-next-line no-nested-ternary
              color: p.extendedProps.color !== null ? p.extendedProps.color !== '#ffffff' ? p.extendedProps.color : null : null,
              textColor: '#ffffff',
              resourceId: p.extendedProps.roomId,
              extendedProps: {
                _id: p._id,
                userId: p.extendedProps.userId,
                userInfo: p.extendedProps.userInfo,
                roomId: p.extendedProps.roomId,
                coursePackageId: p.extendedProps.coursePackageId,
                code: p.extendedProps.code,
                color: p.extendedProps.color,
                description: p.extendedProps.description,
                show: p.extendedProps.show,
                paid: p.extendedProps.paid,
                sumPrice: p.extendedProps.sumPrice,
                roomPrice: p.extendedProps.roomPrice,
                coursePackagePrice: p.extendedProps.coursePackagePrice,
                optionPrice: p.extendedProps.optionPrice,
                options: p.extendedProps.options,
              },
              allDay: p.allDay,
            })),
          )
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addNew() {
      this.event = {}
      this.isEventHandlerSidebarActive = true
    },
    grabEventDataFromEventApi(eventApi) {
      const {
        id,
        title,
        start,
        end,
        url,
        // eslint-disable-next-line object-curly-newline
        extendedProps: { _id, userId, userInfo, roomId, coursePackageId, code, color, description, show, paid, sumPrice, roomPrice, coursePackagePrice, optionPrice, options },
        allDay,
      } = eventApi

      return {

        id,
        title: `${title}`,
        start,
        end,
        url,
        extendedProps: {
          _id,
          userId,
          userInfo,
          roomId,
          coursePackageId,
          code,
          color,
          description,
          show,
          paid,
          sumPrice,
          roomPrice,
          coursePackagePrice,
          optionPrice,
          options,
        },
        allDay,
      }
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

    <style lang="scss">
    @import "@core/scss/vue/apps/calendar.scss";
    </style>
