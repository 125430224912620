<template>
    <b-modal size="lg" :visible="isModalActive" :title="$t('Picture of options')" ok-title="Accept"
        @change="(val) => $emit('update:is-modal-active', val)">

        <b-table-simple responsive>
            <b-thead head-variant="primary">
                <b-tr>
                    <b-td v-for="(item, index) in dataHeaders" :key="index">{{ item }}</b-td>
                    <b-td style="width: 30%;">{{ $t('Image') }}</b-td>
                    <b-td style="width: 50%;">{{ $t('Action') }}</b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(item, index) in dataTable" :key="index">
                    <b-td v-for="(itemOption, indexOption) in item.option" :key="indexOption">{{ itemOption.name }}</b-td>
                    <b-td>
                        <b-row>
                            <b-img v-if="item.image" :src="item.image" fluid thumbnail width="80px"
                                :alt="'Image_' + item._id" />
                            <b-img v-else :src="require('@/assets/images/blank/no_image.png')" fluid thumbnail width="80px"
                                :alt="'Image_' + item._id" />

                        </b-row>
                    </b-td>
                    <b-td>
                        <b-form-group>
                            <!-- media -->
                            <b-media no-body>
                                <b-media-body class="mt-75 ml-75">
                                    <b-form-file :id="'refProductInputEl' + index" accept=".webp, .jpeg, .jpg, .png, .gif"
                                        :hidden="true" plain @change="inputImageRenderer($event, index)" />
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                                        class="mb-75 mr-75" @click="openFormFile(index)">
                                        {{ $t("Upload") }}
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                                        size="sm" class="mb-75 mr-75" @click="resetImage(index)">
                                        {{ $t("Reset") }}
                                    </b-button>
                                    <b-card-text>{{ $t("Allowed") }} JPG, GIF {{ $t("or") }} PNG.
                                        {{ $t("Max size of") }} 700KB</b-card-text>
                                        <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                                </b-media-body>
                            </b-media>
                            <!--/ media -->
                        </b-form-group>
                    </b-td>

                </b-tr>
            </b-tbody>
        </b-table-simple>
        <template #modal-footer>
            <div class="w-100">

                <div class="float-right">

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" size="sm" class="mr-2"
                        @click="accept">
                        {{ $t('Accept') }}
                        <feather-icon icon="ShoppingBagIcon" size="12" />
                    </b-button>

                </div>

            </div>
        </template>
    </b-modal>
</template>
  
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BTableSimple, BThead, BTh, BTbody, BTr, BTd } from 'bootstrap-vue'

import {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BMedia,
    BMediaBody,
    BImg,
    BCardText,
    BFormFile,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
    components: {
        BTableSimple, BThead, BTh, BTbody, BTr, BTd,
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        Cleave,
        BModal,
        BRow,
        // eslint-disable-next-line vue/no-unused-components
        BCol,
        BForm,
        // eslint-disable-next-line vue/no-unused-components
        BFormInput,
        BFormGroup,
        // eslint-disable-next-line vue/no-unused-components
        BButton,
        BMedia,
        BMediaBody,
        BImg,
        BCardText,
        BFormFile,

        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    mixins: [togglePasswordVisibility],
    model: {
        prop: 'isModalActive',
        event: 'update:is-modal-active',
    },
    props: {
        storeModuleName: {
            type: String,
            required: true,
        },
        isModalActive: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            default: () => { },
        },
        show: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            dataId: null,
            dataHeaders: [],
            dataTable: [],
            required,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },

    },
    watch: {
        isModalActive(val) {
            if (val) {
                if (Object.entries(this.data).length === 0) {
                    this.$refs.formData.reset()
                    this.initValues()
                } else {
                    const {
                        _id,
                    } = this.data
                    this.dataId = _id
                    if (_id) {
                        this.getImageProductOption(_id)
                    }
                }
            }
        },
    },
    methods: {
        resetImage(index) {
            this.dataTable[index].image = ''
        },
        openFormFile(index) {
            document.getElementById('refProductInputEl' + index).click()
        },
        inputImageRenderer(input, index) {
            // eslint-disable-next-line prefer-destructuring
            if (input.target.files && input.target.files[0]) {
                if (input.target.files[0].size > 700000) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
                        },
                    })
                    return
                }

                const reader = new FileReader()
                reader.onload = e => {
                    this.dataTable[index].image = e.target.result
                    //   this.images.push(e.target.result)
                    // const obj = { image: e.target.result }
                    // this.$store
                    //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
                    //   .then(resp => {
                    //     console.log(resp)
                    //     this.image = resp.data.data.url
                    //   })
                    //   .catch(err => {
                    //     console.error(err)
                    //   })
                }
                reader.readAsDataURL(input.target.files[0])
            }
        },
        getImageProductOption(id) {
            this.$emit('update:show', true)

            store
                .dispatch(`${this.storeModuleName}/getImageProductOption`, {
                    id,
                })
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                    // eslint-disable-next-line no-unused-vars
                    this.dataHeaders = result.data.headers
                    this.dataTable = result.data.data
                    this.$emit('update:show', false)


                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                    // eslint-disable-next-line no-unused-vars
                }).catch(error => {
                    this.$emit('update:show', false)

                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // console.log('fetchUsers Error : ', error)
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
        accept() {
            this.$emit('update:show', true)

            store
                .dispatch(`${this.storeModuleName}/saveImageProductOption`, {
                    dataTable: this.dataTable,
                })
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                    // eslint-disable-next-line no-unused-vars
                    this.$emit('update:show', false)


                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                    // eslint-disable-next-line no-unused-vars
                }).catch(error => {
                    this.$emit('update:show', false)

                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // console.log('fetchUsers Error : ', error)
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
  