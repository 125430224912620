<template>
  <b-modal
    id="add-new-user-modal"
    :visible="isModalActive"
    bg-variant="white"
    size="xl"
    shadow
    backdrop
    ok-title="Accept"
    @change="val => $emit('update:is-modal-active', val)"
  >
    <template #default="{ hide }">

      <!-- Form -->
      <draggable
        v-model="lists"
        class="list-group cursor-move"
        tag="ul"
        @end="sort()"
      >
        <b-list-group-item
          v-for="(item,index) in lists"
          :key="index"
          tag="li"
        >
          <div class="d-flex">
            <b-avatar :text="(index+1).toString()" />
            <div class="ml-25">
              <b-card-text class="font-weight-bold mb-0">
                {{ showFromCurrentLanguage(item.name) }}
              </b-card-text>
              <small>{{ showFromCurrentLanguage(item.name) }}</small>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
    </template>
    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          class="float-right"
        >
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-modal-active', false)"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  BCard,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BInputGroupText,
  BInputGroupPrepend,
  BAvatar,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    BModal,
    BCard,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    BAvatar,
  BListGroup,
  BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroupText,
    BInputGroupPrepend,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      lists: [],
      required,
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        this.get()
        if (Object.entries(this.data).length === 0) {
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
        }
      }
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    get() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
        type: 'package',
      }
      this.show = true
      store
        .dispatch(`${this.storeModuleName}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.lists = result.data.data
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    initValues() {
      this.dataId = null
      this.lists = []
    },
    sort() {
      const obj = {
        lists: this.lists,
      }
      console.log(obj)
      this.$emit('update:show', true)
      store
        .dispatch(`${this.storeModuleName}/sort`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
        //   this.$emit('update:is-modal-active', false)
          this.$emit('update:show', false)

          this.showToast('success', 'top-right', 'OrderBy Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          this.$emit('update:show', false)
          // this.showToast('danger', 'bottom-right')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    #add-new-user-modal {
      .vs__dropdown-menu {
        max-height: 200px !important;
      }
    }
    .list-group-item {
  transition: all 1s
}
    </style>
