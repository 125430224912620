import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    SWITCH_CHANGE(state, item) {
      const ItemIndex = state.respData.data.findIndex(p => p._id === item.id)
      if (ItemIndex > -1) {
        const nameChild = item.name.split('.')
        if (nameChild.length === 3) {
          state.respData.data[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
        } else if (nameChild.length === 2) {
          state.respData.data[nameChild[0]][nameChild[1]] = item.status
        } else {
          state.respData.data[item.name] = item.status
        }
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        let path = `/api/web/application/eCommerce/reservationCoursePackage/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`
        if (item.type) {
          path += `&type=${item.type}`
        }
        axios.get(path)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/reservationCoursePackage/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/reservationCoursePackage/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/reservationCoursePackage/delete', item)
          .then(response => {
            commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    sort({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/reservationCoursePackage/sort', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/eCommerce/reservationCoursePackage/switchChange', item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
