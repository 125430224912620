<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :store-module-name-category="STORE_MODULE_NAME_CATEGORY"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <sort
      :store-module-name="STORE_MODULE_NAME"
      :data="modalData"
      :is-modal-active.sync="isModalActive"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t('entries') }}</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex  mr-1"
                  @click="modalEdit({})"
                >
                  <span class="align-middle">{{ $t('Sort') }}</span>
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarEdit({})"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <!-- <b-form-file
                  @change="importFileJson"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".json"
                /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <!-- Column: Images -->
          <template #cell(image)="data">
            <b-row>
              <b-img
                v-if="data.item.image"
                :src="data.item.image"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
            </b-row>
          </template>
          <template #cell(name)="data">
            <p
              v-for="(item, index) in data.item.name"
              :key="index"
              class="align-middle ml-50"
            >
              {{ item.lang.toUpperCase() }}: {{ item.value }}
            </p>
          </template>
          <template #cell(description)="data">
            <p
              v-for="(item, index) in data.item.description"
              :key="index"
              class="align-middle ml-50"
            >
              {{ item.lang.toUpperCase() }}: {{ item.value }}
            </p>
          </template>
          <template #cell(show)="data">
            <b-form-checkbox
              :checked="data.item.show"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('show', $event, data.item._id)"
            />
          </template>
          <template #cell(duration)="data">
            <span
              class="align-middle ml-50"
            >
              {{ convertMinutesToReadableTime(data.item.duration) }}
            </span>
          </template>
          <template #cell(type)="data">
            <span
              class="align-middle ml-50"
            >
              {{ data.item.type | firstStringUpper }}
            </span>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="sidebarEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormCheckbox,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/reservationCoursePackage'
import storeModuleCategory from '@/store/services/eCommerce/reservationCategoryCoursePackage'
import debounce from 'debounce'
import Sidebar from './DataSidebar.vue'
import Sort from './Sort.vue'

const STORE_MODULE_NAME = 'reservationCoursePackage'
const STORE_MODULE_NAME_CATEGORY = 'reservationCategoryCoursePackage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    Sort,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BFormCheckbox,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      isModalActive: false,
      modalData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'description', label: this.$t('Description'), sortable: false },
        { key: 'show', label: this.$t('Show'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'duration', label: this.$t('Duration'), sortable: false },
        { key: 'type', label: this.$t('Type'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.registerModule(STORE_MODULE_NAME_CATEGORY, storeModuleCategory)
    this.get()
    this.getCategory()

    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.unregisterModule(STORE_MODULE_NAME_CATEGORY)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_CATEGORY }
  },
  methods: {
    switchChange(name, status, id) {
      const obj = {
        id,
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {})
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    convertMinutesToReadableTime(minutes) {
      const MINUTES_IN_YEAR = 525600 // 60 * 24 * 365
      const MINUTES_IN_MONTH = 43800 // 60 * 24 * 30
      const MINUTES_IN_DAY = 1440 // 60 * 24
      const MINUTES_IN_HOUR = 60

      const years = Math.floor(minutes / MINUTES_IN_YEAR)
      minutes %= MINUTES_IN_YEAR

      const months = Math.floor(minutes / MINUTES_IN_MONTH)
      minutes %= MINUTES_IN_MONTH

      const days = Math.floor(minutes / MINUTES_IN_DAY)
      minutes %= MINUTES_IN_DAY

      const hours = Math.floor(minutes / MINUTES_IN_HOUR)
      minutes %= MINUTES_IN_HOUR

      // สร้างข้อความโดยละเว้นค่า 0
      const parts = []
      if (years) parts.push(`${years} ${this.$t('Year')}`)
      if (months) parts.push(`${months} ${this.$t('Month')}`)
      if (days) parts.push(`${days} ${this.$t('Day')}`)
      if (hours) parts.push(`${hours} ${this.$t('Hour')}`)
      if (minutes) parts.push(`${minutes} ${this.$t('Minute')}`)

      // รวมข้อความ
      return parts.join(' ')
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        type: 'course',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getCategory() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_CATEGORY}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      this.get()
    },
    modalEdit(item) {
      this.modalData = item
      this.isModalActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, { id })
            // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      this.currentPage = 1

      this.get()
    },
    nextPage(page) {
      this.currentPage = page
      this.get()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .b-col-custom {
    margin-top: 0.5rem;
  }
  </style>
