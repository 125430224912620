<template>
  <b-card
    v-if="respData && $ability.can('read', this.$route.meta.resource)"
    class="blog-edit-wrapper"
  >
    <b-tabs v-model="tabIndex">
      <b-tab
        v-for="(itemLang, indexLang) in language"
        :key="indexLang"
        :title="$t(itemLang.name)"
      >
        <b-form class="mt-2">
          <b-row>
            <b-col
              cols="4"
              class="mb-2"
            >

              <div class="code">
                <div class="html-code">
                  <b-tabs>
                    <b-tab :title="'Editor'">
                      <h1>Editor</h1>

                      <vue-editor
                        v-if="respData.html[indexLang]"
                        :id="`editor${itemLang.lang}`"
                        :ref="`editor_${itemLang.lang}`"
                        :value="respData.html[indexLang]['value']"
                        :editor-toolbar="customToolbar"
                        @imageAdded="handleImageAdded"
                        @input="
                          inputLanguage(
                            $event,
                            itemLang.lang,
                            indexLang,
                            'htmlCode',
                          )
                        "
                      />
                    </b-tab>
                    <b-tab :title="'HTML'">
                      <h1>HTML</h1>
                      <b-form-textarea
                        v-if="respData.htmlCode[indexLang]"

                        :value="respData.htmlCode[indexLang]['value']"

                        @input="
                          inputLanguage(
                            $event,
                            itemLang.lang,
                            indexLang,
                            'htmlCode',
                          )
                        "
                      />
                    </b-tab>
                  </b-tabs>

                </div>
                <div class="css-code">
                  <h1>CSS</h1>
                  <b-form-textarea
                    v-if="respData.htmlCSS[indexLang]"
                    :value="respData.htmlCSS[indexLang]['value']"

                    @input="
                      inputLanguage(
                        $event,
                        itemLang.lang,
                        indexLang,
                        'htmlCSS',
                      )
                    "
                  />
                </div>
              </div>

            </b-col>
            <b-col
              cols="8"
              class="mb-2"
            >
              <h1>{{ $t('Example') }}</h1>
              <iframe
                :id="`htmlRender_${itemLang.lang}`"
                style="width: 100%;
                        height: 100%;
                        border: none;
                        border-radius:4px;
                        border:solid 0.5px #999;
                        "
              />
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="saveChanges"
              >
                {{ $t('Save Changes') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="cancelBtn"
              >
                {{ $t('Cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormTextarea,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'

import Ripple from 'vue-ripple-directive'
import StoreModule from '@/store/services/eCommerce/promoteList'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line import/no-unresolved
import { VueEditor } from 'vue2-editor'

const STORE_MODULE_NAME = 'promoteList'

export default {
  components: {
    quillEditor,
    VueEditor,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BTabs,
    BTab,
    // quillEditor,
    // Editor,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      dataId: null,
      respData: {
        html: [],
        htmlCode: [],
        htmlCSS: [],
      },
      loadingHtml: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // [
        //   { align: '' },
        //   { align: 'center' },
        //   { align: 'right' },
        //   { align: 'justify' },
        // ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
      ],
      OptionRenderBy: [
        { name: 'Flutter', value: 'flutter' },
        { name: 'Html', value: 'html' },
      ],
    }
  },
  computed: {
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    tabIndex(val) {
      if (this.respData.htmlCode.length > 0 && this.respData.htmlCSS.length > 0) {
        this.renderCode(val)
      }
    },
  },
  created() {
    const Id = this.$route.params.id // subMenu
    this.dataId = Id
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    if (this.dataId !== 'add') {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.dataId })
        .then(result => {
          if (result.data.data) {
            // renderBy
            this.respData = result.data.data
            if (this.respData.htmlCode.length < 1) {
              this.respData.htmlCode = this.respData.html
            }
            if (this.respData.htmlCSS.length < 1) {
              this.respData.htmlCSS = [{
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
              ]
            }
            const index = this.language.findIndex(e => e.lang === this.$i18n.locale)
            this.renderCode(index)
          }
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    // tabInput(value) {
    //   this.renderCode(value)
    // },
    renderCode(indexLang) {
      const locale = this.language[indexLang].lang
      const result = document.querySelector(`#htmlRender_${locale}`)

      const htmlCode = this.respData.htmlCode[indexLang].value
      const htmlCSS = this.respData.htmlCSS[indexLang].value

      result.contentDocument.body.innerHTML = `<style>${htmlCSS}</style>${htmlCode}`
    },
    // eslint-disable-next-line no-unused-vars
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      console.log('handleImageAdded')
      console.log(file)
      // var formData = new FormData();
      // formData.append("image", file);

      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData
      // })
      //   .then(result => {
      //     const url = result.data.url; // Get url from response
      //     Editor.insertEmbed(cursorLocation, "image", url);
      //     resetUploader();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'html') {
        if (this.respData.html[index].lang === lang) {
          this.respData.html[index].value = input
        }
      } else if (type === 'htmlCode') {
        if (this.respData.htmlCode[index].lang === lang) {
          this.respData.htmlCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'htmlCSS') {
        if (this.respData.htmlCSS[index].lang === lang) {
          this.respData.htmlCSS[index].value = input
          this.renderCode(index)
        }
      }
    },
    uploadFunction(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 700KB`,
            },
          })
          return
        }
        this.loadingHtml = true
        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = { image: e.target.result }
          this.$store
            .dispatch(`${STORE_MODULE_NAME}/uploadImage`, obj)
            .then(resp => {
              this.loadingHtml = false

              const range = this.$refs.quillEdit.quill.getSelection()
              this.$refs.quillEdit.quill.insertEmbed(
                range.index,
                'image',
                resp.data.url,
              )
            })
            .catch(err => {
              this.loadingHtml = false

              console.error(err)
            })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const html = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            }]
            const obj = {
              id: this.dataId,

              // html: this.respData.html,
              html: html.map((e, index) => ({
                lang: e.lang,
                value: `<style>${this.respData.htmlCSS[index].value}</style>${this.respData.htmlCode[index].value}`,
              })),
              htmlCode: this.respData.htmlCode,
              htmlCSS: this.respData.htmlCSS,
            }
            if (this.dataId !== null && this.dataId !== 'add') {
              store
                .dispatch(`${STORE_MODULE_NAME}/updateHTML`, obj)
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                  this.show = false
                })
                .catch(error => {
                  this.show = false
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
    showHtml() {
      console.log(this.respData.htmlData)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 700KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.respData.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.ql-container {
  height: 250px;
}

.code textarea {

    height:200px;

}

</style>
