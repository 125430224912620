<template>
  <div>
    <change-time-modal
      :store-module-name="storeModuleName"
      :is-modal-active.sync="isChangeTimeModalActive"
      :data="changeTimeData"
    />
    <b-modal
      :visible="isModalActive"
      :title="$t('Package')"
      ok-title="Accept"
      size="xl"
      no-close-on-backdrop
      @change="(val) => $emit('update:is-modal-active', val)"
    >
      <b-overlay :show="show" rounded="sm">
        <b-row>
          <b-col md="4">
            <b-card border-variant="primary">
              <div
                v-if="data.firstName"
                class="d-flex align-items-center justify-content-center"
              >
                <div class="justify-content-center">
                  <div class="text-center mb-1">
                    <b-avatar
                      v-if="data.image"
                      class="text-center"
                      rounded="sm"
                      square
                      size="10rem"
                      :src="data.image"
                    />
                    <b-avatar
                      v-else
                      class="text-center"
                      rounded="sm"
                      square
                      size="6rem"
                      :src="require('@/assets/images/blank/no_image.png')"
                    />
                  </div>
                  <div class="mb-1">
                    {{ data.firstName }} {{ data.lastName }}
                  </div>
                </div>
              </div>
              <p>{{ $t("Select Package") }}</p>
              <hr />
              <b-form-group :label="$t('Package')" label-for="register-package">
                <div>
                  <v-select
                    id="register-package"
                    v-model="packageId"
                    size="sm"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="coursePackages"
                    :reduce="(coursePackages) => coursePackages._id"
                    :clearable="false"
                    class="select-size mb-2"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="addReservationPackage()"
                  >
                    <feather-icon icon="PlusIcon" />
                    <span class="align-middle">{{ $t("Buy") }}</span>
                  </b-button>
                </div>
              </b-form-group>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card border-variant="primary">
              <p>{{ $t("Package List") }}</p>
              <hr />
              <b-row>
                <b-col
                  v-for="(item, index) in OptionReservationPackage"
                  :key="index"
                  md="6"
                >
                  <b-card border-variant="primary">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p>{{ index + 1 }}. {{ item.name }}</p>
                      <div>
                        <b-button
                          class="mr-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="editData(item)"
                        >
                          <feather-icon icon="ClockIcon" />
                          <!-- <span class="align-middle">Add Users</span> -->
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          @click="deleteData(item._id)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <!-- <span class="align-middle">Add Users</span> -->
                        </b-button>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BAvatar,
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ChangeTimeModal from './ChangeTimeModal.vue'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from "@/store";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BAvatar,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    ChangeTimeModal,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isModalActive",
    event: "update:is-modal-active",
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameCoursePackage: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      packageId: null,
      isChangeTimeModalActive: false,
      changeTimeData: {},
      required,
    };
  },
  computed: {
    coursePackages() {
      return store.state[this.storeModuleNameCoursePackage].respData != null
        ? store.state[this.storeModuleNameCoursePackage].respData.data.map(
            (e) => ({
              _id: e._id,
              name: `${this.showFromCurrentLanguage(e.name)}  (${this.$t(
                "Duration"
              )} : ${this.convertMinutesToReadableTime(e.duration)})`,
            })
          )
        : [];
    },
    OptionReservationPackage() {
      return store.state[this.storeModuleName].respDataPackages != null
        ? store.state[this.storeModuleName].respDataPackages.data.map((e) => ({
            _id: e._id,
            name: `[${this.subStringObjectId(
              e._id
            )}] ${this.showFromCurrentLanguage(e.package.name)} (${this.$t(
              "Remaining Time"
            )} : ${this.convertMinutesToReadableTime(e.remainingTime)})`,
            remainingTime: e.remainingTime,
            userInfoId: this.dataId,
          }))
        : [];
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues();
        } else {
          const { _id } = this.data;
          console.log(this.data);
          this.dataId = _id;
          this.getReservationPackage(_id);
        }
      }
    },
  },
  methods: {
    subStringObjectId(data) {
      return data.substr(20, data.length);
    },
    convertMinutesToReadableTime(minutes) {
      const MINUTES_IN_YEAR = 525600; // 60 * 24 * 365
      const MINUTES_IN_MONTH = 43800; // 60 * 24 * 30
      const MINUTES_IN_DAY = 1440; // 60 * 24
      const MINUTES_IN_HOUR = 60;

      const years = Math.floor(minutes / MINUTES_IN_YEAR);
      minutes %= MINUTES_IN_YEAR;

      const months = Math.floor(minutes / MINUTES_IN_MONTH);
      minutes %= MINUTES_IN_MONTH;

      const days = Math.floor(minutes / MINUTES_IN_DAY);
      minutes %= MINUTES_IN_DAY;

      const hours = Math.floor(minutes / MINUTES_IN_HOUR);
      minutes %= MINUTES_IN_HOUR;

      // สร้างข้อความโดยละเว้นค่า 0
      const parts = [];
      if (years) parts.push(`${years} ${this.$t("Year")}`);
      if (months) parts.push(`${months} ${this.$t("Month")}`);
      if (days) parts.push(`${days} ${this.$t("Day")}`);
      if (hours) parts.push(`${hours} ${this.$t("Hour")}`);
      if (minutes) parts.push(`${minutes} ${this.$t("Minute")}`);

      // รวมข้อความ
      return parts.join(" ");
    },
    getReservationPackage(id) {
      const obj = {
        id,
      };
      this.$emit("update:show", true);

      store
        .dispatch(`${this.storeModuleName}/getReservationPackage`, obj)
        .then((result) => {
          this.$emit("update:show", false);

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          this.$emit("update:show", false);
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    closeModal() {
      this.$emit("update:is-modal-active", false);
    },
    initValues() {
      this.dataId = null;
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex((e) => e.lang === this.$i18n.locale);
      if (indexLang > -1) {
        return data[indexLang].value;
      }
      return "";
    },
    editData(data) {
      this.changeTimeData = data;
      this.isChangeTimeModalActive = true;
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to delete")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            store
              .dispatch(`${this.storeModuleName}/deleteReservationPackage`, {
                id: this.dataId,
                packageId: id,
              })
              // eslint-disable-next-line no-unused-vars
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    addReservationPackage() {
      const obj = {
        id: this.dataId,
        packageId: this.packageId,
      };

      store
        .dispatch(`${this.storeModuleName}/addReservationPackage`, obj)
        .then((result) => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
