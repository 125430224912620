<template>
  <div>
    <b-card>
      <div class="mb-1 d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <h4>
            {{ $t("Sponsor Bonus Setting") }}
          </h4>
        </div>
        <div class="d-flex">
          <b-form-checkbox
            :checked="sponsorBonusShow"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="switchChange('sponsorBonusShow', $event)"
          />
        </div>
      </div>
      <b-table-simple class="mb-1">
        <b-thead head-variant="primary">
          <b-tr>
            <b-td>
              {{ $t('Class') }}
            </b-td>
            <b-td>{{ $t('Coverage') }}</b-td>
            <b-td>{{ $t('Calculate Type') }}</b-td>
            <b-td>{{ $t('Amount') }}</b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,index) in list"
            :key="index"
          >
            <b-td>{{ showFromCurrentLanguage(item.name) }} </b-td>
            <b-td>
              <b-form-checkbox-group
                :id="`checkbox-slots-${index}`"
                v-model="list[index].coverage"
                :options="CoverageOptions"
                :name="`checkbox-options-slots-${index}`"
                :aria-describedby="`ariaDescribedby-${index}`"
              />

            </b-td>
            <b-td>
              <v-select
                id="Options"
                v-model="list[index].calculateType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="Options"
                :clearable="false"
                :reduce="(Options) => Options.value"
              />
            </b-td>
            <b-td>
              <cleave
                id="amount"
                v-model="list[index].amount"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Enter amount')"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-button
        variant="primary"
        class="mr-2"
        @click="saveChanged"
      >
        {{ $t('Save Changes') }}
      </b-button>
      <b-button
        class="mr-2"
      >
        {{ $t('Discard') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BTr,
  BTd,
  BTbody,
  BTableSimple,
  BThead,
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import storeModuleMemberLevel from '@/store/services/eCommerce/memberLevel'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

const STORE_MODULE_NAME = 'mlm'
const STORE_MODULE_NAME_MEMBER_LEVEL = 'memberLevel'

export default {
  components: {
    BTr,
    BTd,
    BTbody,
    BTableSimple,
    BThead,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,

    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      perPageOptions,
      optionCleave: {
        number: {
          numeral: true,
        },
      },

      list: [
        // {
        //   id: 1,
        //   name: 'Bronze',
        //   point: 1000,
        //   amount: 50000,
        // },
        // {
        //   id: 2,
        //   name: 'Silver',
        //   point: 2500,
        //   amount: 120000,
        // },
        // {
        //   id: 3,
        //   name: 'Gold',
        //   point: 5000,
        //   amount: 250000,
        // },
        // {
        //   id: 4,
        //   name: 'Platinum',
        //   point: 10000,
        //   amount: 500000,
        // },
        // {
        //   id: 5,
        //   name: 'Ruby',
        //   point: 10000,
        //   amount: 1250000,
        // },
        // {
        //   id: 6,
        //   name: 'Emerald',
        //   point: 10000,
        //   amount: 2250000,
        // },
        // {
        //   id: 7,
        //   name: 'Diamond',
        //   point: 10000,
        //   amount: 5000000,
        // },
      ],
    }
  },
  computed: {
    Options() {
      return [
        { name: this.$t('Percent'), value: 'percent' },
        { name: this.$t('Constant'), value: 'constant' },
      ]
    },
    CoverageOptions() {
      return [

        { text: this.$t('Children'), value: 'Children' },
        { text: this.$t('Grand Children'), value: 'Grandchildren' },
        { text: this.$t('Great-Grand Children'), value: 'Great-grandchildren' },
      ]
    },
    sponsorBonusShow: {
      get() {
        return store.state[STORE_MODULE_NAME].respData != null
          ? store.state[STORE_MODULE_NAME].respData.sponsorBonusShow
          : false
      },
    },
    sponsorBonus() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.sponsorBonus
        : []
    },
  },
  watch: {
    sponsorBonus: {
      handler(newValue, oldValue) {
        if (newValue) {
          for (let index = 0; index < this.list.length; index += 1) {
            const { coverage, calculateType, amount } = this.returnValue(this.list[index].membershipLevelId)
            this.list[index].coverage = coverage
            this.list[index].calculateType = calculateType
            this.list[index].amount = amount
          }
        }
      },
      deep: true, // ใช้ deep เพื่อติดตามการเปลี่ยนแปลงในระดับลึก
    },
  },
  mounted() {
    if (this.sponsorBonus) {
      for (let index = 0; index < this.list.length; index += 1) {
        const { coverage, calculateType, amount } = this.returnValue(this.list[index].membershipLevelId)
        this.list[index].coverage = coverage
        this.list[index].calculateType = calculateType
        this.list[index].amount = amount
      }
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) {
      store.registerModule(
        STORE_MODULE_NAME_MEMBER_LEVEL,
        storeModuleMemberLevel,
      )
    }
    this.getMemberLevel()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) store.unregisterModule(STORE_MODULE_NAME_MEMBER_LEVEL)
  },
  setup() {
    return { STORE_MODULE_NAME_MEMBER_LEVEL }
  },
  methods: {
    switchChange(name, status) {
      const obj = {
        name,
        status,
      }
      console.log(obj)
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveChanged() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/sponsorBonus`, {
          sponsorBonus: this.list.map(e => ({
            ...e,
            amount: e.amount.toString().replaceAll(',', ''),
          })),
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER_LEVEL}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const dataMemberLevel = result.data.data
          const format = []
          for (let index = 0; index < dataMemberLevel.length; index += 1) {
            const { coverage, calculateType, amount } = this.returnValue(dataMemberLevel[index]._id)
            format.push({
              id: index + 1,
              name: dataMemberLevel[index].name,
              membershipLevelId: dataMemberLevel[index]._id,
              coverage,
              calculateType,
              amount,
            })
          }

          // dataMemberLevel.forEach((recommender) => {
          //   dataMemberLevel.forEach((invitee) => {
          //     const amount = this.returnAmount(recommender._id, invitee._id);

          //     format.push({
          //       recommender: recommender._id,
          //       invitee: invitee._id,
          //       amount,
          //     });
          //   });
          // });
          this.list = format;
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    returnValue(membershipLevelId) {
      const index = this.sponsorBonus.findIndex(
        e => e.membershipLevelId === membershipLevelId,
      )
      if (index > -1) {
        return { coverage: this.sponsorBonus[index].coverage, calculateType: this.sponsorBonus[index].calculateType, amount: this.sponsorBonus[index].amount }
      }
      return { coverage: [], calculateType: 'percent', amount: 0 }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
