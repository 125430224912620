<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }}
                      700KB</b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <a
                href="https://fontawesome.com/v5/cheatsheet"
                target="_blank"
              >https://fontawesome.com/v5/cheatsheet</a>
              <b-form-group
                :label="$t('IconCode') + ' (Version < 2.0.0)'"
                label-for="register-IconCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="IconCode"
                  vid="IconCode"
                  rules="required"
                >
                  <b-form-input
                    id="register-IconCode"
                    v-model="icon.iconCode"
                    name="register-IconCode"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IconCode')"
                    @input="inputIconCode($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <a
                href="https://icons8.com/line-awesome"
                target="_blank"
              >https://icons8.com/line-awesome</a>
              <span> {{ $t(`If there is a - sign, change the letter after the - to uppercase, then delete the -`)
              }}</span>
              <b-form-group
                :label="$t('IconName') + ' (Version > 2.0.0)'"
                label-for="register-IconName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="IconName"
                  vid="IconName"
                  rules="required"
                >
                  <b-form-input
                    id="register-IconName"
                    v-model="icon.name"
                    name="register-IconName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('IconName')"
                    @input="inputIconName($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-for="(item, index) in language"
              :key="index"
              cols="12"
            >
              <!-- firstName -->
              <!-- firstName -->
              <b-form-group
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="item.name"
                  :vid="item.name"
                >
                  <b-form-input
                    v-if="name[index]"
                    :id="item.name"
                    :name="item.name"
                    :value="name[index]['value']"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t(item.name)"
                    @input="inputLanguage($event, item.lang, index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('renderIcon')"
                label-for="register-renderIcon"
              >
                <validation-provider
                  #default="{ errors }"
                  name="renderIcon"
                  vid="renderIcon"
                  rules="required"
                >
                  <v-select
                    id="register-renderIcon"
                    v-model="renderIcon"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionRenderIcon"
                    :reduce="(OptionRenderIcon) => OptionRenderIcon.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('typeMenu')"
                label-for="register-typeMenu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="typeMenu"
                  vid="typeMenu"
                  rules="required"
                >
                  <v-select
                    id="register-typeMenu"
                    v-model="typeMenu"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionTypeMenu"
                    :reduce="(OptionTypeMenu) => OptionTypeMenu.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('positionMenu')"
                label-for="register-positionMenu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="positionMenu"
                  vid="positionMenu"
                  rules="required"
                >
                  <v-select
                    id="register-positionMenu"
                    v-model="positionMenu"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionPositionMenu"
                    :reduce="(OptionPositionMenu) => OptionPositionMenu.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              v-if="positionMenu === 'HOME_PAGE' || positionMenu === 'HOME_PAGE_BOTTOM'"
              cols="12"
            >
              <b-form-group
                :label="$t('Action')"
                label-for="register-action"
              >
                <validation-provider
                  #default="{ errors }"
                  name="action"
                  vid="action"
                  rules="required"
                >
                  <v-select
                    id="register-action"
                    v-model="action"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionAction"
                    :reduce="(OptionAction) => OptionAction.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              v-if="action === 'link'"
              cols="12"
            >
              <b-form-group
                :label="$t('Target')"
                label-for="register-target"
              >
                <validation-provider
                  #default="{ errors }"
                  name="target"
                  vid="target"
                  rules="required"
                >
                  <v-select
                    id="register-target"
                    v-model="target"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionTarget"
                    :reduce="(OptionTarget) => OptionTarget.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col
              v-if="action === 'link'"
              cols="12"
            >
              <!-- Detail -->
              <b-form-group
                :label="$t('Url Link')"
                label-for="register-urlLink"
              >
                <validation-provider
                  #default="{ errors }"
                  name="UrlLink"
                  vid="urlLink"
                  rules="required"
                >
                  <b-form-input
                    id="register-urlLink"
                    v-model="urlLink"
                    name="register-urlLink"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Url Link')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="action === 'actionMenu'"
              cols="12"
            >
              <b-form-group
                :label="$t('Tag Menu')"
                label-for="register-tagMenu"
              >
                <v-select
                  v-model="tagMenu"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionTagMenu"
                  :reduce="OptionTagMenu => OptionTagMenu.value"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>

              <template v-if="tagMenu === 'PRODUCT_CATEGORY'">
                <b-form-group
                  :label="$t('Product Category')"
                  label-for="register-name"
                >
                  <v-select
                    v-model="productCategoryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionProductCategory"
                    :reduce="(OptionProductCategory) => OptionProductCategory._id"
                    :clearable="false"
                    label="name"
                    @search="onSearchProductCategory"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageProductCategory<= 1 ? true : false"
                            @click="nextPageProductCategory(currentPageProductCategory - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >{{ currentPageProductCategory }}</b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="
                              currentPageProductCategory * perPageProductCategory >=
                                queriedItemsProductCategory
                                ? true
                                : false
                            "
                            @click="nextPageProductCategory(currentPageProductCategory + 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-form-group>
              </template>
              <template v-if="tagMenu === 'QUESTIONNAIRE'">
                <b-form-group
                  :label="$t('Questionnaire')"
                  label-for="register-name"
                >
                  <v-select
                    v-model="questionnaireId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionQuestionnaire"
                    :reduce="(OptionQuestionnaire) => OptionQuestionnaire._id"
                    :clearable="false"
                    label="name"
                    @search="onSearchQuestionnaire"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageQuestionnaire<= 1 ? true : false"
                            @click="nextPageQuestionnaire(currentPageQuestionnaire - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >{{ currentPageQuestionnaire }}</b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="
                              currentPageQuestionnaire * perPageQuestionnaire >=
                                queriedItemsQuestionnaire
                                ? true
                                : false
                            "
                            @click="nextPageQuestionnaire(currentPageQuestionnaire+ 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-form-group>
              </template>

            </b-col>

            <b-col
              v-if="OptionRenderStyles.length>0"
              cols="12"
            >
              <!-- username -->
              <!-- <b-form-group :label="$t('renderStyle')" label-for="register-renderStyle">
                <validation-provider #default="{ errors }" name="renderStyle" vid="renderStyle" rules="required">
                  <b-form-input id="register-renderStyle" v-model="renderStyle" name="register-renderStyle"
                    :state="errors.length > 0 ? false : null" :placeholder="$t('renderStyle')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-form-group
                :label="$t('renderStyle')"
                label-for="register-renderStyle"
              >
                <validation-provider
                  #default="{ errors }"
                  name="renderStyle"
                  vid="renderStyle"
                  rules="required"
                >
                  <v-select
                    id="register-renderStyle"
                    v-model="renderStyle"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionRenderStyles"
                    :reduce="(OptionRenderStyles) => OptionRenderStyles.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
              <b-row class="justify-content-md-center mb-2">
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="imageRenderSelected()"
                        ref="previewRenderStyle"
                        rounded
                        :src="(imageRenderSelected().image)"
                        width="150"
                      />
                    </b-link>
                  <!--/ avatar -->
                  </b-media-aside>
                </b-media>
              </b-row>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="memberMenu">
                <b-form-checkbox
                  id="memberMenu"
                  v-model="memberMenu"
                  name="checkbox-1"
                >
                  {{ $t("Member Menu") }} ({{ $t('Menu can be seen when logged in') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="specialMenu">
                <b-form-checkbox
                  id="specialMenu"
                  v-model="specialMenu"
                  name="checkbox-1"
                >
                  {{ $t("Special Menu") }} ({{ $t('Admin menu can be seen in the application') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="branchMenu">
                <b-form-checkbox
                  id="branchMenu"
                  v-model="branchMenu"
                  name="checkbox-1"
                >
                  {{ $t("Branch Menu") }} ({{ $t('A menu for branch administrators can be seen in the application') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="chargeCredit">
                <b-form-checkbox
                  id="chargeCredit"
                  v-model="chargeCredit"
                  name="checkbox-1"
                >
                  {{ $t("Charge credit") }} ({{ $t('Menu for calculating credit when visiting') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BFormCheckbox,
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameProductCategory: {
      type: String,
      required: true,
    },
    storeModuleNameQuestionnaire: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: null,
      iconFromSystem: null,
      icon: {
        iconCode: '',
        name: '',
      },
      iconSelected: {
        iconCode: '',
        name: '',
      },
      renderIcon: '',
      renderStyle: 0,
      name: [],
      tagMenu: '',
      positionMenu: '',
      action: 'none',
      target: 'IN_APP',
      urlLink: '',
      typeMenu: '',
      specialMenu: false,
      memberMenu: false,
      branchMenu: false,
      tagPermissionPage: '',
      productId: null,
      couponId: null,
      couponCategoryId: null,
      productCategoryId: null,
      questionnaireId: null,
      chargeCredit: false,
      currentPageProductCategory: 0,
      searchQueryProductCategory: '',
      perPageProductCategory: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageQuestionnaire: 0,
      searchQueryQuestionnaire: '',
      perPageQuestionnaire: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      required,
    }
  },
  computed: {
    OptionRenderIcon() {
      return [
        { name: this.$t('Image'), value: 'image' },
        { name: this.$t('System'), value: 'system' },
        // { name: this.$t('Icon Flutter'), value: 'iconFlutter' },
      ]
    },
    OptionTypeMenu() {
      return [
        { name: this.$t('Menu'), value: 'MENU' },
        { name: this.$t('Page'), value: 'PAGE' },
      ]
    },
    OptionTagMenu() {
      return [
        { name: this.$t('Product Category'), value: 'PRODUCT_CATEGORY' },
        { name: this.$t('Questionnaire'), value: 'QUESTIONNAIRE' },
        { name: this.$t('Calendar Content'), value: 'CALENDAR_CONTENT' },
        { name: this.$t('Delivery'), value: 'DELIVERY' },
        { name: this.$t('Orders'), value: 'ORDERS' },
        { name: this.$t('Register'), value: 'REGISTER' },
        { name: this.$t('Products'), value: 'PRODUCTS' },
        { name: this.$t('Chat'), value: 'CHAT' },
        { name: this.$t('Workflow'), value: 'WORKFLOW' },
        { name: this.$t('Income'), value: 'INCOME' },
        { name: this.$t('Coupon'), value: 'COUPON' },
      ]
    },
    OptionPositionMenu() {
      return [
        { name: this.$t('Home Page'), value: 'HOME_PAGE' },
        { name: this.$t('Home Page Bottom'), value: 'HOME_PAGE_BOTTOM' },
        { name: this.$t('Bottom Navbar'), value: 'BOTTOM_NAVBAR' },
      ]
    },
    OptionAction() {
      return [
        { name: this.$t('None'), value: 'none' },
        { name: this.$t('Flutter'), value: 'flutter' },
        { name: this.$t('Link'), value: 'link' },
        { name: this.$t('Action Menu'), value: 'actionMenu' },
        { name: this.$t('More Menu'), value: 'moreMenu' },
      ]
    },
    OptionTarget() {
      return [
        { name: this.$t('In App'), value: 'IN_APP' },
        { name: this.$t('External App'), value: 'EXTERNAL_APP' },
      ]
    },
    OptionRenderStyles() {
      if (this.tagMenu === 'HOME_PAGE') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/homePage/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/homePage/preset2.png') },
          { name: `${this.$t('Style')} 3`, value: 2, image: require('@/assets/images/example/preset/homePage/preset3.png') },
          { name: `${this.$t('Style')} 4`, value: 3, image: require('@/assets/images/example/preset/homePage/preset4.png') },
          { name: `${this.$t('Style')} 5`, value: 4, image: require('@/assets/images/example/preset/homePage/preset5.png') },
          { name: `${this.$t('Style')} 6`, value: 5, image: require('@/assets/images/example/preset/homePage/preset6.png') },
          { name: `${this.$t('Style')} 7`, value: 6, image: require('@/assets/images/example/preset/homePage/preset7.png') },
          { name: `${this.$t('Style')} 8`, value: 7, image: require('@/assets/images/example/preset/homePage/preset7.png') },
          { name: `${this.$t('Style')} 9`, value: 8, image: require('@/assets/images/example/preset/homePage/preset7.png') },
          { name: `${this.$t('Style')} 10`, value: 9, image: require('@/assets/images/example/preset/homePage/preset7.png') },
          { name: `${this.$t('Style')} 11`, value: 10, image: require('@/assets/images/example/preset/homePage/preset7.png') },
        ]
      } if (this.tagMenu === 'PRODUCT') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/product/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/product/preset2.png') },
          { name: `${this.$t('Style')} 3`, value: 2, image: require('@/assets/images/example/preset/product/preset3.png') },
          { name: `${this.$t('Style')} 4`, value: 3, image: require('@/assets/images/example/preset/product/preset4.png') },
        ]
      } if (this.tagMenu === 'CALENDAR_CONTENT') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/calendarContent/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/calendarContent/preset2.png') },
        ]
      } if (this.tagMenu === 'ME') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/calendarContent/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/calendarContent/preset2.png') },
        ]
      }
      return [
        { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/api/preset1.png') },
        { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/api/preset2.png') },
      ]
    },
    OptionProductCategory() {
      return store.state[this.storeModuleNameProductCategory].respData != null
        ? store.state[this.storeModuleNameProductCategory].respData.data.map(
          e => {
            const indexLang = e.name.findIndex(
              name => name.lang === this.$i18n.locale,
            )
            if (indexLang > -1) {
              return {
                _id: e._id,
                name: e.name[indexLang].value,
              }
            }
            return {
              _id: e._id,
              name: e.name[0].value,
            }
          },
        )
        : []
    },
    queriedItemsProductCategory() {
      return store.state[this.storeModuleNameProductCategory].respData != null
        ? store.state[this.storeModuleNameProductCategory].respData.max
        : 0
    },
    OptionQuestionnaire() {
      return store.state[this.storeModuleNameQuestionnaire].respData != null
        ? store.state[this.storeModuleNameQuestionnaire].respData.data.map(
          e => ({
            _id: e._id,
            name: e.name,
          }),
        )
        : []
    },
    queriedItemsQuestionnaire() {
      return store.state[this.storeModuleNameQuestionnaire].respData != null
        ? store.state[this.storeModuleNameQuestionnaire].respData.max
        : 0
    },
    language() {
      return store.state.appConfig.systemConfig ? store.state.appConfig.systemConfig.language : []
    },
  },
  watch: {
    tagMenu(val) {
      if (val) {
        if (val === 'PRODUCT_CATEGORY') {
          this.currentPageProductCategory = 0

          this.getProductCategory()
        }
        if (val === 'QUESTIONNAIRE') {
          this.currentPageQuestionnaire = 0

          this.getQuestionnaire()
        }
      }
    },
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id, image, iconFromSystem, icon, iconSelected, renderIcon, renderStyle, name, tagMenu, positionMenu, action, target, urlLink, typeMenu, specialMenu, memberMenu, branchMenu, tagPermissionPage,
            productId,
            couponId,
            couponCategoryId,
            productCategoryId,
            questionnaireId,
            chargeCredit,
          } = this.data
          this.dataId = _id
          this.name = name
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(element => element.lang === this.language[index].lang)
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(element => element.lang === this.name[index].lang)
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          this.image = image
          this.iconFromSystem = iconFromSystem
          this.icon = icon
          this.iconSelected = iconSelected
          this.renderIcon = renderIcon
          this.renderStyle = renderStyle
          this.tagMenu = tagMenu
          if (this.tagMenu === 'PRODUCT_CATEGORY') {
            this.getProductCategory()
          }
          if (this.tagMenu === 'QUESTIONNAIRE') {
            this.getQuestionnaire()
          }
          this.positionMenu = positionMenu
          this.action = action
          this.target = target
          this.urlLink = urlLink
          this.typeMenu = typeMenu
          this.specialMenu = specialMenu
          this.memberMenu = memberMenu
          this.branchMenu = branchMenu
          this.tagPermissionPage = tagPermissionPage
          if (productId) {
            this.productId = productId
          }
          if (couponId) {
            this.couponId = couponId
          }
          if (couponCategoryId) {
            this.couponCategoryId = couponCategoryId
          }
          if (productCategoryId) {
            this.productCategoryId = productCategoryId
          }
          if (questionnaireId) {
            this.questionnaireId = questionnaireId
          }

          this.chargeCredit = chargeCredit
        }
      }
    },
  },
  methods: {
    nextPageQuestionnaire(page) {
      this.currentPageQuestionnaire = page
      this.getQuestionnaire()
    },
    onSearchQuestionnaire(query) {
      this.searchQueryQuestionnaire = query
      this.currentPageQuestionnaire = 0
      this.getQuestionnaire()
    },
    getQuestionnaire() {
      const obj = {
        currentPage: this.currentPageQuestionnaire,
        pageSize: this.perPageQuestionnaire,
        searchQuery: this.searchQueryQuestionnaire,
        position: 0,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameQuestionnaire}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    nextPageProductCategory(page) {
      this.currentPageProductCategory = page
      this.getProductCategory()
    },
    onSearchProductCategory(query) {
      this.searchQueryProductCategory = query
      this.currentPageProductCategory = 0
      this.getProductCategory()
    },
    getProductCategory() {
      const obj = {
        currentPage: this.currentPageProductCategory,
        pageSize: this.perPageProductCategory,
        searchQuery: this.searchQueryProductCategory,
        position: 0,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameProductCategory}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    imageRenderSelected() {
      return this.OptionRenderStyles.find(e => e.value === this.renderStyle) ?? null
    },
    inputIconCode(input) {
      console.log(input)
      this.icon.iconCode = input
      this.iconSelected.iconCode = input
    },
    inputIconName(input) {
      console.log(input)
      this.icon.name = input
      this.iconSelected.name = input
    },
    inputLanguage(input, lang, index) {
      console.log(input, lang, index)
      if (this.name[index].lang === lang) {
        this.name[index].value = input
      }
      console.log(this.name)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 700000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.iconFromSystem = ''
      this.icon = {
        iconCode: '',
        name: '',
      }
      this.iconSelected = {
        iconCode: '',
        name: '',
      }
      this.renderIcon = ''
      this.renderStyle = 0
      this.tagMenu = ''
      this.positionMenu = ''
      this.action = 'none'
      this.target = 'IN_APP'
      this.urlLink = ''
      this.typeMenu = ''
      this.specialMenu = false
      this.memberMenu = false
      this.branchMenu = false
      this.tagPermissionPage = ''
      this.productId = null
      this.couponId = null
      this.couponCategoryId = null
      this.productCategoryId = null
      this.questionnaireId = null
      this.chargeCredit = false
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            iconFromSystem: this.iconFromSystem,
            icon: this.icon,
            iconSelected: this.iconSelected,
            renderIcon: this.renderIcon, // ชนิดการแสดงของ Icon
            renderStyle: this.renderStyle, // ชนิดการแสดงของ Style
            name: this.name,
            tagMenu: this.tagMenu,
            positionMenu: this.positionMenu,
            action: this.action,
            target: this.target,
            urlLink: this.urlLink,
            typeMenu: this.typeMenu,
            specialMenu: this.specialMenu,
            memberMenu: this.memberMenu,
            branchMenu: this.branchMenu,
            tagPermissionPage: this.tagPermissionPage,
            productId: this.productId,
            couponId: this.couponId,
            couponCategoryId: this.couponCategoryId,
            productCategoryId: this.productCategoryId,
            questionnaireId: this.questionnaireId,
            chargeCredit: this.chargeCredit,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                this.initValues()
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                this.initValues()

                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
