<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-card no-body v-on="$listeners">
      <draggable v-model="list" class="list-group cursor-move" tag="ul">
        <b-list-group-item v-for="(item, index) in list" :key="index" tag="li">
          <div class="d-flex">
            <b-avatar :text="(index + 1).toString()" />
            <div class="ml-25">
              <b-card-text class="font-weight-bold mb-0">
                {{ showFromCurrentLanguage(item.name) }}
              </b-card-text>
              <small>{{ showFromCurrentLanguage(item.name) }}</small>
            </div>
            <div class="d-flex flex-grow-1 justify-content-end">
              <b-badge
                class="d-block  align-content-center mr-1"
                :variant="
                  item.positionMenu === 'BOTTOM_NAVBAR'
                    ? 'primary'
                    : item.positionMenu === 'HOME_PAGE_BOTTOM'
                    ? 'warning'
                    : 'info'
                "
              >
                {{
                  item.positionMenu === "BOTTOM_NAVBAR"
                    ? $t("Bottom Navbar")
                    : item.positionMenu === "HOME_PAGE_BOTTOM"
                    ? $t("Home Page Bottom")
                    : $t("Home Page")
                }}
              </b-badge>
              <b-badge
                class=" align-content-center"
                style="width: 50px;"
                :variant="item.show === true ? 'success' : 'secondary'"
                >{{ item.show === true ? $t("Show") : $t("Hide") }}
              </b-badge>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="d-flex"
        @click="saveChanges()"
      >
        <feather-icon icon="PlusIcon" />
        <span class="align-middle">{{ $t("Save Changes") }}</span>
      </b-button>
    </b-card>
    <!-- draggable -->
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BListGroupItem,
  BCardText,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import storeModule from "@/store/services/eCommerce/menu";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

const STORE_MODULE_NAME = "menu";

export default {
  components: {
    BCard,
    BButton,
    draggable,
    BListGroupItem,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      list: [],
    };
  },
  setup() {
    return { STORE_MODULE_NAME };
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    };

    this.show = true;
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then((result) => {
        this.list = result.data.data;
        this.show = false;
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch((error) => {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Error"),
            icon: "ErrorIcon",
            variant: "danger",
            text: this.$t(error.response.data.message),
          },
        });
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      });
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex((e) => e.lang === this.$i18n.locale);
      if (indexLang > -1) {
        return data[indexLang].value;
      }
      return "";
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to save")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            const obj = {
              dataMenus: this.list,
            };

            store
              .dispatch(`${STORE_MODULE_NAME}/saveSort`, obj)
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
  },
};
</script>

<style>
.list-group-item {
  transition: all 1s;
}
</style>
