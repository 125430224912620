<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-card-text>
                {{ $t('Image') }} {{ $t('All') }} {{ images.length }}
                {{ $t('Image') }}
              </b-card-text>
            </b-col>
            <b-col cols="12">

              <draggable
                v-model="images"
                group="myGroup"
                tag="div"
                class="row"
              >
                <b-col
                  v-for="(item, index) in images"
                  :key="index"

                  cols="4"
                >
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mr-1 mb-1 imgs-show"
                    height="120"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index)"
                  >{{ $t('Delete') }} ({{ index + 1 }})</b-button>
                </b-col>
              </draggable>
            </b-col>
            <!-- <b-col
              v-for="(item, index) in images"
              :key="index"
              md="4"
              cols="6"
            >
              <b-img
                fluid-grow
                :src="item"
                rounded
                class="mr-1 mb-1 imgs-show"
                height="120"
              />
              <b-button
                variant="danger"
                class="btn-remove"
                @click="removeImage(index)"
              >{{ $t('Delete') }} ({{ index + 1 }})</b-button>
            </b-col> -->
            <b-col cols="12">
              <b-form-group>
                <!-- media -->
                <b-media no-body>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >{{ $t('Upload') }}</b-button>
                    <b-form-file
                      ref="refInputEl"
                      multiple
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImagesRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >{{ $t('Reset') }}</b-button>
                    <!--/ reset -->
                    <b-card-text>
                      {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                      {{ $t('Max size of') }} 600KB
                    </b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              v-if="optionNameTranslate === 'single'"
              cols="12"
            >
              <!-- firstName -->
              <b-form-group
                :label="$t('Name')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <template v-if="optionNameTranslate === 'multiple'">
              <b-col
                v-for="(item, index) in language"
                :key="`${index}_multiple`"
                cols="12"
              >
                <!-- firstName -->
                <!-- firstName -->
                <b-form-group
                  :label="$t(item.name)"
                  :label-for="item.name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :vid="item.name"
                  >
                    <b-form-input
                      v-if="nameTranslate[index]"
                      :id="item.name"
                      :name="item.name"
                      :value="nameTranslate[index]['value']"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t(item.name)"
                      @input="inputLanguage($event, item.lang, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12">
              <b-form-group
                :label="$t('Option Name Translate')"
                label-for="register-optionNameTranslate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="optionNameTranslate"
                  vid="optionNameTranslate"
                  rules="required"
                >
                  <v-select
                    id="register-optionNameTranslate"
                    v-model="optionNameTranslate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionNameTranslate"
                    :reduce="OptionNameTranslate => OptionNameTranslate.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('Detail')"
                label-for="register-detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  vid="detail"
                  rules="required"
                >
                  <b-form-input
                    id="register-detail"
                    v-model="detail"
                    name="register-detail"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Detail -->
              <b-form-group
                :label="`${$t('Price')} (${$t('Baht')})`"
                label-for="register-price"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Price"
                  vid="price"
                  rules="required"
                >
                  <b-form-input
                    id="register-price"
                    v-model="price"
                    name="register-price"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Price')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Detail -->
              <b-form-group
                :label="`${$t('Weight')} (${$t('gram')})`"
                label-for="register-weight"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Weight"
                  vid="weight"
                  rules="required"
                >
                  <b-form-input
                    id="register-weight"
                    v-model="weight"
                    name="register-weight"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Weight')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    :label="$t('Width')"
                    label-for="register-width"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="width"
                      vid="width"
                      rules=""
                    >
                      <cleave
                        v-model="width"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Width')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    :label="$t('Length')"
                    label-for="register-length"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="length"
                      vid="length"
                      rules=""
                    >
                      <cleave
                        v-model="length"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Length')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    :label="$t('Height')"
                    label-for="register-height"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="height"
                      vid="height"
                      rules=""
                    >
                      <cleave
                        v-model="height"
                        class="form-control"
                        :raw="false"
                        :options="optionCleave.number"
                        :placeholder="$t('Height')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('renderBy')"
                label-for="register-renderBy"
              >
                <validation-provider
                  #default="{ errors }"
                  name="renderBy"
                  vid="renderBy"
                  rules="required"
                >
                  <v-select
                    id="register-renderBy"
                    v-model="renderBy"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionRenderBy"
                    :reduce="OptionRenderBy => OptionRenderBy.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('statusProduct')"
                label-for="register-statusProduct"
              >
                <validation-provider
                  #default="{ errors }"
                  name="statusProduct"
                  vid="statusProduct"
                  rules="required"
                >
                  <v-select
                    id="register-statusProduct"
                    v-model="statusProduct"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionStatusProduct"
                    :reduce="OptionStatusProduct => OptionStatusProduct.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="recommendProduct">
                <b-form-checkbox
                  id="recommendProduct"
                  v-model="recommendProduct"
                  name="checkbox-1"
                >{{ $t('Recommend Product') }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="bonus">
                <b-form-checkbox
                  id="bonus"
                  v-model="bonus"
                  name="checkbox-1"
                >{{
                  $t('Bonus')
                }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="opensecondHand">
                <b-form-checkbox
                  id="opensecondHand"
                  v-model="secondHand"
                  name="checkbox-2"
                >{{ $t('Second Hand') }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="openSystemShippingCost">
                <b-form-checkbox
                  id="openSystemShippingCost"
                  v-model="openSystemShippingCost"
                  name="checkbox-3"
                >{{ $t('Open System Shipping Cost') }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="openSystemShippingCost"
              cols="12"
            >
              <b-form-group
                :label="$t('Shipping Cost Calculation')"
                label-for="register-shippingCostCalculation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="shippingCostCalculation"
                  vid="shippingCostCalculation"
                  rules="required"
                >
                  <v-select
                    id="register-shippingCostCalculation"
                    v-model="shippingCostCalculation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionShiping"
                    :reduce="OptionShiping => OptionShiping.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="
                openSystemShippingCost && shippingCostCalculation === 'amount'
              "
              cols="12"
            >
              <!-- Detail -->
              <b-form-group
                :label="$t('Shiping Price Per Amount')"
                label-for="register-shipingPricePerAmount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="shipingPricePerAmount"
                  vid="shipingPricePerAmount"
                  rules="required"
                >
                  <b-form-input
                    id="register-shipingPricePerAmount"
                    v-model="shipingPricePerAmount"
                    name="register-shipingPricePerAmount"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Shiping Price Per Amount')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="
                openSystemShippingCost &&
                  (shippingCostCalculation === 'order' ||
                    shippingCostCalculation === 'weight')
              "
              cols="12"
            >
              <b-form-group
                :label="$t('Shiping Price Per Order/Weight')"
                label-for="register-statusProduct"
              >
                <validation-provider
                  #default="{ errors }"
                  name="shipingPricePerOrderWeight"
                  vid="shipingPricePerOrderWeight"
                  rules="required"
                >
                  <v-select
                    id="register-shipingPricePerOrderWeight"
                    v-model="shipingPricePerOrderWeight"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="respDataSelectShipping"
                    :reduce="
                      respDataSelectShipping => respDataSelectShipping._id
                    "
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Stock By')"
                label-for="register-stockBy"
              >
                <validation-provider
                  #default="{ errors }"
                  name="stockBy"
                  vid="stockBy"
                  rules="required"
                >
                  <v-select
                    id="register-stockBy"
                    v-model="stockBy"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionStock"
                    :reduce="OptionStock => OptionStock.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Open With')"
                label-for="register-openWith"
              >
                <validation-provider
                  #default="{ errors }"
                  name="openWith"
                  vid="openWith"
                  rules="required"
                >
                  <v-select
                    id="register-openWith"
                    v-model="openWith"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionOpenWith"
                    :reduce="OptionOpenWith => OptionOpenWith.value"
                    class="select-size-lg"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="openWith === 'url'"
              cols="12"
            >
              <!-- Detail -->
              <b-form-group
                :label="$t('URL Product')"
                label-for="register-urlProduct"
              >
                <validation-provider
                  #default="{ errors }"
                  name="urlProduct"
                  vid="urlProduct"
                  rules="required"
                >
                  <b-form-input
                    id="register-urlProduct"
                    v-model="urlProduct"
                    name="register-urlProduct"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('URL Product')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Affiliate Product Group')"
                label-for="register-affiliateProductGroup"
              >
                <validation-provider
                  #default="{ errors }"
                  name="affiliateProductGroup"
                  vid="affiliateProductGroup"
                  rules=""
                >
                  <v-select
                    id="register-affiliateProductGroup"
                    v-model="affiliateProductGroupId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="respDataSelectAffiliateProductGroup"
                    :reduce="
                      respDataSelectAffiliateProductGroup => respDataSelectAffiliateProductGroup._id
                    "
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >{{ $t('Submit') }}</b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >{{
              $t('Cancel')
            }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  // BMediaAside,
  BMediaBody,
  // BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import draggable from 'vuedraggable'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    // BMediaAside,
    BMediaBody,
    // BLink,
    BImg,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    vSelect,
    Cleave,
    draggable,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      images: [],
      name: '',
      nameTranslate: [],
      detail: '',
      price: '',
      secondHand: false,
      weight: '',
      statusProduct: '',
      optionNameTranslate: 'single',
      stockBy: '',
      renderBy: '',
      openWith: 'app',
      urlProduct: '',
      openSystemShippingCost: false,
      shippingCostCalculation: 'amount',
      shipingPricePerAmount: 0,
      shipingPricePerOrderWeight: '',
      recommendProduct: false,
      width: 0,
      length: 0,
      height: 0,
      bonus: false,
      affiliateProductGroupId: null,
      OptionRenderBy: [
        { name: 'Html', value: 'html' },
        { name: 'Flutter', value: 'flutter' },
      ],
      OptionOpenWith: [
        { name: 'Application', value: 'app' },
        { name: 'URL', value: 'url' },
      ],
      required,
    }
  },
  computed: {
    OptionStatusProduct() {
      return [
        { name: this.$t('Show'), value: 'show' },
        { name: this.$t('Hide'), value: 'hide' },
        { name: this.$t('Pre Order'), value: 'pre_order' },
      ]
    },
    OptionNameTranslate() {
      return [
        { name: this.$t('Single language'), value: 'single' },
        { name: this.$t('Multiple language'), value: 'multiple' },
      ]
    },
    OptionShiping() {
      return [
        { name: this.$t('Amount'), value: 'amount' },
        { name: this.$t('Order'), value: 'order' },
        { name: this.$t('Weight'), value: 'weight' },
      ]
    },
    OptionStock() {
      return [
        { name: this.$t('Product'), value: 'product' },
        { name: this.$t('Option'), value: 'option' },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    respDataSelectShipping() {
      return store.state.shiping.respData != null
        ? store.state.shiping.respData.data
        : []
    },
    respDataSelectAffiliateProductGroup() {
      return store.state.affiliateProductGroup.respData != null
        ? store.state.affiliateProductGroup.respData.data.map(e => {
          const indexLang = e.name.findIndex(name => name.lang === this.$i18n.locale)
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
  },
  watch: {
    shippingCostCalculation(val) {
      if (val) {
        if (val === 'order') {
          this.getShiping()
        } else if (val === 'weight') {
          this.getShiping()
        }
      }
    },
    isSidebarActive(val) {
      if (val) {
        this.initValues()
        this.getAffiliateProductGroup()
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          if (this.nameTranslate.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.nameTranslate.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.nameTranslate.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
              console.log(index)
            }
          }
        } else {
          const {
            _id,
            images,
            name,
            nameTranslate,
            detail,
            price,
            secondHand,
            weight,
            stock,
            limitMax,
            sold,
            point,
            discount,
            renderBy,
            optionNameTranslate,
            stockBy,
            openSystemShippingCost,
            shipingPricePerAmount,
            shipingCostByOrderId,
            shipingCostByWeightId,
            shippingCostCalculation,
            statusProduct,
            recommendProduct,
            bonus,
            width,
            length,
            height,
            openWith,
            urlProduct,
            affiliateProductGroupId,
          } = this.data
          this.dataId = _id
          this.images = images
          this.name = name
          this.width = width || 0
          this.length = length || 0
          this.height = height || 0
          this.nameTranslate = nameTranslate
          if (this.nameTranslate.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.nameTranslate.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.nameTranslate.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.nameTranslate.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.nameTranslate[index].lang,
              )
              if (indexLanguage < 0) {
                this.nameTranslate.splice(index, 1)
              }
            }
          }
          this.detail = detail
          this.price = price
          this.secondHand = secondHand || false
          this.weight = weight
          this.stock = stock
          this.limitMax = limitMax
          this.sold = sold
          this.point = point
          this.discount = discount
          this.renderBy = renderBy
          this.optionNameTranslate = optionNameTranslate
          this.stockBy = stockBy
          this.openSystemShippingCost = openSystemShippingCost
          this.shipingPricePerAmount = shipingPricePerAmount
          this.shippingCostCalculation = shippingCostCalculation
          if (shippingCostCalculation === 'order') {
            this.shipingPricePerOrderWeight = shipingCostByOrderId
          } else if (shippingCostCalculation === 'weight') {
            this.shipingPricePerOrderWeight = shipingCostByWeightId
          }
          if (openWith) {
            this.openWith = openWith
          }
          if (urlProduct) {
            this.urlProduct = urlProduct
          }
          this.statusProduct = statusProduct
          this.recommendProduct = recommendProduct
          this.bonus = bonus
          if (affiliateProductGroupId) {
            this.affiliateProductGroupId = affiliateProductGroupId._id
          }
        }
      }
    },
  },
  methods: {
    getAffiliateProductGroup() {
      store
        .dispatch('affiliateProductGroup/get', {
          currentPage: 0,
          pageSize: 20,
          searchQuery: '',
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {

        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getShiping() {
      // this.shipingPricePerOrderWeight = '';
      const obj = {
        currentPage: 1,
        pageSize: 0,
        searchQuery: '',
        type: this.shippingCostCalculation,
      }

      store
        .dispatch('shiping/get', obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    inputLanguage(input, lang, index) {
      console.log(input, lang, index)
      if (this.nameTranslate[index].lang === lang) {
        this.nameTranslate[index].value = input
      }
      console.log(this.nameTranslate)
    },
    removeImage(index) {
      this.images.splice(index, 1)
    },
    inputImagesRenderer(input) {
      console.log(this.images)
      const result = this.images
      if (input.target.files.length > 0) {
        const { files } = input.target
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          const reader = new FileReader()

          reader.onload = function (e) {
            const contents = e.target.result
            result.push(contents)
          }

          reader.readAsDataURL(file)
        }
      }
      this.images = result
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 5000000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 5MB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.images.push(e.target.result)
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.images = []
    },
    initValues() {
      this.dataId = null
      this.images = []
      this.name = ''
      this.nameTranslate = []
      this.detail = ''
      this.price = ''
      this.secondHand = false
      this.weight = ''
      this.stock = ''
      this.limitMax = ''
      this.sold = ''
      this.point = ''
      this.discount = ''
      this.renderBy = ''
      this.optionNameTranslate = 'single'
      this.stockBy = ''
      this.openSystemShippingCost = false
      this.shipingPricePerAmount = 0
      this.statusProduct = ''
      this.recommendProduct = false
      this.bonus = false
      this.width = 0
      this.length = 0
      this.height = 0
      this.openWith = 'app'
      this.urlProduct = ''
      this.affiliateProductGroupId = null
      // this.$refs.refInputEl.reset()
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            images: this.images,
            name: this.name,
            nameTranslate: this.nameTranslate,
            detail: this.detail,
            price: this.price,
            secondHand: this.secondHand,
            weight: this.weight,
            stock: this.stock,
            limitMax: this.limitMax,
            sold: this.sold,
            point: this.point,
            discount: this.discount,
            renderBy: this.renderBy,
            optionNameTranslate: this.optionNameTranslate,
            stockBy: this.stockBy,
            shippingCostCalculation: this.shippingCostCalculation,
            openSystemShippingCost: this.openSystemShippingCost,
            shipingPricePerAmount: this.shipingPricePerAmount,
            shipingPricePerOrderWeight: this.shipingPricePerOrderWeight,
            statusProduct: this.statusProduct,
            recommendProduct: this.recommendProduct,
            bonus: this.bonus,
            width: this.width.replaceAll(',', ''),
            length: this.length.replaceAll(',', ''),
            height: this.height.replaceAll(',', ''),
            openWith: this.openWith,
            urlProduct: this.urlProduct,
            affiliateProductGroupId: this.affiliateProductGroupId,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.initValues()
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.initValues()
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}
</style>
