<template>
  <div>
    <b-card>
      <div class="mb-1 d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <h4>
            {{ $t("Cycle 4 week") }}
          </h4>
        </div>
        <div class="d-flex">
          <b-form-checkbox
            :checked="cycleShow"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="switchChange('cycleShow', $event)"
          />
        </div>
      </div>
      <b-row class="mb-1">
        <b-col md="4">
          <b-form-group
            :label="$t('Day')"
            label-for="day"
          >
            <cleave
              id="day"
              v-model="day"
              class="form-control"
              :raw="false"
              :options="optionCleave.number"
              :placeholder="$t('Enter day')"
            />
            <span>{{ $t('Number of days in cycle') }}</span>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('Point')"
            label-for="point"
          >
            <cleave
              id="point"
              v-model="point"
              class="form-control"
              :raw="false"
              :options="optionCleave.number"
              :placeholder="$t('Enter point')"
            />
            <span>{{ $t('Number of points in cycle') }}</span>

          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            :label="$t('Amount')"
            label-for="amount"
          >
            <cleave
              id="amount"
              v-model="amount"
              class="form-control"
              :raw="false"
              :options="optionCleave.number"
              :placeholder="$t('Enter amount')"
            />
            <span>{{ $t('Amount paid in a cycle') }}</span>

          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mr-2"
        @click="saveChanged"
      >
        {{ $t('Save Changes') }}
      </b-button>
      <b-button
        class="mr-2"
      >
        {{ $t('Discard') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

const STORE_MODULE_NAME = 'mlm'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BFormGroup,
  BFormCheckbox,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      day: 7,
      point: 100,
      amount: 5000,
      perPageOptions,
    }
  },
  computed: {
    cycleShow: {
      get() {
        return store.state[STORE_MODULE_NAME].respData != null
          ? store.state[STORE_MODULE_NAME].respData.cycleShow
          : false;
      },
    },
    cycle() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.cycle : {
        day: 0,
        point: 0,
        amount: 0,
      }
    },
  },
  watch: {
    cycle: {
      handler(newValue, oldValue) {
        // console.log('oldValue has changed:', oldValue)
        // console.log('newValue has changed:', newValue)
        if (newValue) {
          this.day = newValue.day
          this.point = newValue.point
          this.amount = newValue.amount
        }
      },
      deep: true, // ใช้ deep เพื่อติดตามการเปลี่ยนแปลงในระดับลึก
    },
  },
  mounted() {
    if (this.cycle) {
      this.day = this.cycle.day
      this.point = this.cycle.point
      this.amount = this.cycle.amount
    }
  },
  methods: {
    switchChange(name, status) {
      const obj = {
        name,
        status,
      };
      console.log(obj);
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Success"),
              icon: "SuccessIcon",
              variant: "success",
              text: this.$t(result.data.message),
            },
          });
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          this.show = false;
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    saveChanged() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/cycle`, {
          cycle: {
            day: this.day.toString().replaceAll(',', ''),
            point: this.point.toString().replaceAll(',', ''),
            amount: this.amount.toString().replaceAll(',', ''),
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
