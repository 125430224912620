import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/eCommerce/reservationAvailableTime/get')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/reservationAvailableTime/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    adjust({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/reservationAvailableTime/adjust', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
