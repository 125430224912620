<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('FirstName')"
                label-for="register-firstName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="FirstName"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstName"
                    v-model="firstName"
                    name="register-firstName"
                    :state="errors.length > 0 ? false : null"
                    :disabled="true"
                    :placeholder="$t('FirstName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('LastName')"
                label-for="register-lastName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LastName"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastName"
                    v-model="lastName"
                    name="register-lastName"
                    :state="errors.length > 0 ? false : null"
                    :disabled="true"
                    :placeholder="$t('LastName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="dataId !== null"
              cols="12"
            >
              <!-- username -->
              <b-form-group
                :label="$t('Username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    :disabled="true"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Username')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- phoneNumber -->
              <b-form-group
                :label="$t('PhoneNumber')"
                label-for="register-phoneNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="PhoneNumber"
                  vid="phoneNumber"
                  rules="required"
                >
                  <b-form-input
                    id="register-phoneNumber"
                    v-model="tel"
                    name="register-phoneNumber"
                    :state="errors.length > 0 ? false : null"
                    :disabled="true"
                    :placeholder="$t('PhoneNumber')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Package')"
                label-for="register-package"
              >
                <v-select
                  id="register-package"
                  v-model="packageId"
                  size="sm"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionReservationPackage"
                  :reduce="OptionReservationPackage => OptionReservationPackage._id"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BFormCheckbox,
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameCoursePackage: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      firstName: '',
      lastName: '',
      username: '',
      packageId: null,
      tel: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    OptionStatusType() {
      return [
        { name: this.$t('Pending'), value: 'pending' },
        { name: this.$t('Active'), value: 'active' },
        { name: this.$t('Suspend'), value: 'suspend' },
      ]
    },
    OptionReservationPackage() {
      return store.state[this.storeModuleName].respDataPackages != null
        ? store.state[this.storeModuleName].respDataPackages.data.map(e => ({
          _id: e._id,
          name: `[${this.subStringObjectId(e._id)}] ${this.showFromCurrentLanguage(e.package.name)} (${this.$t('Remaining Time')} : ${this.convertMinutesToReadableTime(e.remainingTime)})`,
        }))
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.initValues()

        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
        } else {
          const {
            _id, image, firstName, lastName, userId, tel, packageId,
          } = this.data
          this.dataId = _id
          this.getReservationPackage(_id)

          this.image = image
          this.firstName = firstName
          this.lastName = lastName
          this.username = userId.username

          if (packageId) {
            this.packageId = packageId._id
          }
          this.tel = tel
        }
      }
    },
  },
  methods: {
    subStringObjectId(data) {
      return data.substr(20, data.length)
    },
    convertMinutesToReadableTime(minutes) {
      const MINUTES_IN_YEAR = 525600 // 60 * 24 * 365
      const MINUTES_IN_MONTH = 43800 // 60 * 24 * 30
      const MINUTES_IN_DAY = 1440 // 60 * 24
      const MINUTES_IN_HOUR = 60

      const years = Math.floor(minutes / MINUTES_IN_YEAR)
      minutes %= MINUTES_IN_YEAR

      const months = Math.floor(minutes / MINUTES_IN_MONTH)
      minutes %= MINUTES_IN_MONTH

      const days = Math.floor(minutes / MINUTES_IN_DAY)
      minutes %= MINUTES_IN_DAY

      const hours = Math.floor(minutes / MINUTES_IN_HOUR)
      minutes %= MINUTES_IN_HOUR

      // สร้างข้อความโดยละเว้นค่า 0
      const parts = []
      if (years) parts.push(`${years} ${this.$t('Year')}`)
      if (months) parts.push(`${months} ${this.$t('Month')}`)
      if (days) parts.push(`${days} ${this.$t('Day')}`)
      if (hours) parts.push(`${hours} ${this.$t('Hour')}`)
      if (minutes) parts.push(`${minutes} ${this.$t('Minute')}`)

      // รวมข้อความ
      return parts.join(' ')
    },
    getReservationPackage(id) {
      const obj = {
        id,
      }

      store
        .dispatch(`${this.storeModuleName}/getReservationPackage`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.firstName = ''
      this.lastName = ''
      this.username = ''
      this.packageId = null
      this.tel = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            packageId: this.packageId,
          }
          this.$emit('update:show', true)

          store
            .dispatch(`${this.storeModuleName}/updateReservationPackage`, obj)
            .then(result => {
              this.$emit('update:is-sidebar-active', false)
              this.$emit('update:show', false)

              // this.showToast('success', 'bottom-right')
            }).catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
              this.$emit('update:show', false)
              // this.showToast('danger', 'bottom-right')
            })
        }
      })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
