<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Close Menu')"
    ok-title="Accept"
    size="lg"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-row class="mt-1">
      <b-col md="12">
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead head-variant="primary">
              <b-tr>
                <b-td style="width: 30%;">{{ $t('System list') }}</b-td>
                <b-td>{{ $t('Setting') }}</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in systemItems" :key="item.key">
                <b-td>{{ $t(item.label) }} :</b-td>
                <b-td>
                  <b-row>
                    <b-form-checkbox
                      v-model="system[item.key]"
                      class="custom-control-primary ml-2"
                      name="check-button"
                      switch
                      @change="switchChange('system.' + item.key, $event)"
                    />
                    <b-card-text>
                      {{
                        system[item.key] != null
                          ? system[item.key] === true
                            ? item.onText
                            : item.offText
                          : "ยังไม่ได้ตั้งค่า"
                      }}
                    </b-card-text>
                  </b-row>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100"></div>
    </template>
  </b-modal>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTd,
  BModal, BRow, BCol, BFormInput,
  BButton, BFormCheckbox, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BTableSimple, BThead, BTbody, BTr, BTd,
    Cleave, vSelect, BModal, BRow, BCol,
    BFormInput, BButton, BFormCheckbox, BCardText,
  },
  directives: { Ripple },
  mixins: [togglePasswordVisibility],
  model: { prop: 'isModalActive', event: 'update:is-modal-active' },
  props: {
    storeModuleName: { type: String, required: true },
    isModalActive: { type: Boolean, required: true },
    data: { type: Object, default: () => ({}) },
    show: { type: Boolean, required: false },
  },
  data() {
    return {
      optionCleave: { number: { numeral: true } },
      dataId: null,
      system: {
        closeCreditSystem: false,
        closePointSystem: false,
        closeMLMSystem: false,
        closeCouponSystem: false,
        closeReservationSystem: false,
        closeWarehouseSystem: false,
        closeAffiliateSystem: false,
        closePromotionSystem: false,
        closeFlashSaleSystem: false,
        closeShippingSystem: false,
        closeContentCalendar: false,
        closeOrder: false,
        closeImagesSetting: false,
        closeStyleApplication: false,
        closeSetupSystem: false,
        closeSetSupport: false,
        closeSetupPaymentSystem: false,
        closeSetupCurrency: false,
        closeTermAndPrivacy: false,
      },
      systemItems: [
        {
          key: 'closeAffiliateSystem',
          label: 'Affiliate',
          onText: 'เปิดระบบค่านายหน้า',
          offText: 'ปิดระบบค่านายหน้า',
        },
        {
          key: 'closeCouponSystem',
          label: 'Coupon',
          onText: 'เปิดระบบคูปอง',
          offText: 'ปิดระบบคูปอง',
        },
        {
          key: 'closeMLMSystem',
          label: 'MLM',
          onText: 'เปิดระบบสายงาน',
          offText: 'ปิดระบบสายงาน',
        },
        {
          key: 'closeCreditSystem',
          label: 'Credit',
          onText: 'เปิดระบบเครดิต',
          offText: 'ปิดระบบเครดิต',
        },
        {
          key: 'closePointSystem',
          label: 'Point',
          onText: 'เปิดระบบคะแนน',
          offText: 'ปิดระบบคะแนน',
        },
        {
          key: 'closeReservationSystem',
          label: 'Reservation',
          onText: 'เปิดระบบจอง',
          offText: 'ปิดระบบจอง',
        },
        {
          key: 'closeWarehouseSystem',
          label: 'Warehouse',
          onText: 'เปิดระบบคลัง',
          offText: 'ปิดระบบคลัง',
        },
        {
          key: 'closePromotionSystem',
          label: 'Promotion',
          onText: 'เปิดระบบโปรโมชั่น',
          offText: 'ปิดระบบโปรโมชั่น',
        },
        {
          key: 'closeFlashSaleSystem',
          label: 'Flash Sale',
          onText: 'เปิดระบบ Flash sale',
          offText: 'ปิดระบบ Flash sale',
        },
        {
          key: 'closeShippingSystem',
          label: 'Shipping',
          onText: 'เปิดระบบการจัดส่ง',
          offText: 'ปิดระบบการจัดส่ง',
        },
        {
          key: 'closeContentCalendar',
          label: 'Content Calendar',
          onText: 'เปิดเนื้อหาปฏิทิน',
          offText: 'ปิดเนื้อหาปฏิทิน',
        },
        {
          key: 'closeOrder',
          label: 'Order',
          onText: 'เปิดคำสั่งซื้อ',
          offText: 'ปิดคำสั่งซื้อ',
        },
        {
          key: 'closeImagesSetting',
          label: 'Images Setting',
          onText: 'เปิดตั้งค่ารูป',
          offText: 'ปิดตั้งค่ารูป',
        },
        {
          key: 'closeStyleApplication',
          label: 'Style Application',
          onText: 'เปิดตั้งค่ารูปแบบแอป',
          offText: 'ปิดตั้งค่ารูปแบบแอป',
        },
        {
          key: 'closeSetupSystem',
          label: 'Setup System',
          onText: 'เปิดตั้งค่าแอป',
          offText: 'ปิดตั้งค่าแอป',
        },
        {
          key: 'closeSetSupport',
          label: 'Set Support',
          onText: 'เปิดตั้งค่าการสนับสนุน',
          offText: 'ปิดตั้งค่าการสนับสนุน',
        },
        {
          key: 'closeSetupPaymentSystem',
          label: 'Setup Payment System',
          onText: 'เปิดตั้งค่าการชำระ',
          offText: 'ปิดตั้งค่าการชำระ',
        },
        {
          key: 'closeSetupCurrency',
          label: 'Setup Currency',
          onText: 'เปิดตั้งค่าสกุลเงิน',
          offText: 'ปิดตั้งค่าสกุลเงิน',
        },
        {
          key: 'closeTermAndPrivacy',
          label: 'Term And Privacy',
          onText: 'เปิดเงื่อนไช และความปลอดภัยข้อมูล',
          offText: 'ปิดเงื่อนไช และความปลอดภัยข้อมูล',
        },
      ],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isModalActive(val) {
      this.initValues()
      if (val) {
        if (!Object.keys(this.data).length) {
          this.$refs.formData && this.$refs.formData.reset()
        } else {
          this.dataId = this.data._id
          this.getCloseMenu()
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      for (const key in this.system) {
        this.system[key] = false
      }
    },
    getCloseMenu() {
      store.dispatch(`${this.storeModuleName}/getCloseMenu`, { id: this.dataId })
        .then(result => {
          const { system } = result.data.data
          if (system) {
            for (const key in system) {
              if (key in this.system) this.system[key] = system[key]
            }
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      store.dispatch(`${this.storeModuleName}/switchChangeCloseMenu`, {
        id: this.dataId, name, status,
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
