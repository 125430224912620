<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col md="6" lg="4">
          <b-overlay :show="showApplication" rounded="sm">
            <b-card title="Logo Application" class="text-center">
              <b-col class="mb-2">
                <b-img
                  v-if="application"
                  ref="previewEl1"
                  :src="application"
                  height="150"
                  width="150"
                />
                <b-img
                  v-else
                  ref="previewEl1"
                  :src="require('@/assets/images/blank/no_image.png')"
                  height="150"
                  width="150"
                />
              </b-col>
              <b-card-text>
                {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                {{ $t('Max size of') }} 700KB
              </b-card-text>

              <b-form-file
                ref="refInputEl0"
                accept=".webp, .jpeg, .jpg, .png, .gif"
                :hidden="true"
                plain
                @change="inputImageRenderer($event, 'application')"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="$refs.refInputEl0.$el.click()"
              >{{ $t('Edit') }}</b-button>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col md="6" lg="4">
          <b-overlay :show="showLogoMainPage" rounded="sm">
            <b-card title="Logo Main Page" class="text-center">
              <b-col class="mb-2">
                <b-img
                  v-if="logoMainPage"
                  ref="previewEl1"
                  :src="logoMainPage"
                  height="150"
                  width="150"
                />
                <b-img
                  v-else
                  ref="previewEl1"
                  :src="require('@/assets/images/blank/no_image.png')"
                  height="150"
                  width="150"
                />
              </b-col>
              <b-card-text>
                {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                {{ $t('Max size of') }} 700KB
              </b-card-text>

              <b-form-file
                ref="refInputEl1"
                accept=".webp, .jpeg, .jpg, .png, .gif"
                :hidden="true"
                plain
                @change="inputImageRenderer($event, 'logoMainPage')"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="$refs.refInputEl1.$el.click()"
              >{{ $t('Edit') }}</b-button>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col md="6" lg="4">
          <b-overlay :show="showAndroid" rounded="sm">
            <b-card title="Icon Android" class="text-center">
              <b-col class="mb-2">
                <b-img v-if="android" ref="previewEl2" :src="android" height="150" width="150" />
                <b-img
                  v-else
                  ref="previewEl2"
                  :src="require('@/assets/images/blank/no_image.png')"
                  height="150"
                  width="150"
                />
              </b-col>
              <b-card-text>
                {{ $t('Allowed') }} PNG. {{ $t('Max size of') }} 1MB
                {{ $t('resolution') }} 512 x 512 {{ $t('pixel') }}
              </b-card-text>

              <b-form-file
                ref="refInputEl2"
                accept=".png"
                :hidden="true"
                plain
                @change="inputImageRenderer($event, 'android')"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="$refs.refInputEl2.$el.click()"
              >{{ $t('Edit') }}</b-button>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col md="6" lg="4">
          <b-overlay :show="showIos" rounded="sm">
            <b-card title="Icon iOS" class="text-center">
              <b-col class="mb-2">
                <b-img v-if="ios" ref="previewEl3" :src="ios" height="150" width="150" />
                <b-img
                  v-else
                  ref="previewEl3"
                  :src="require('@/assets/images/blank/no_image.png')"
                  height="150"
                  width="150"
                />
              </b-col>
              <b-card-text>
                {{ $t('Allowed') }} PNG. {{ $t('Max size of') }} 1MB
                {{ $t('resolution') }} 512 x 512 {{ $t('pixel') }} ({{
                $t('no cutting edge')
                }})
              </b-card-text>

              <b-form-file
                ref="refInputEl3"
                accept=".png"
                :hidden="true"
                plain
                @change="inputImageRenderer($event, 'ios')"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="$refs.refInputEl3.$el.click()"
              >{{ $t('Edit') }}</b-button>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BCardText,
  BImg,
  BOverlay,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";

// eslint-disable-next-line no-unused-vars
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import storeModule from "@/store/services/eCommerce/imagesSettings";

const STORE_MODULE_NAME = "imagesSettings";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BCol,
    BCard,
    BFormFile,
    BCardText,
    BImg,
    BOverlay,
    BButton
  },
  directives: {
    Ripple
  },
  data() {
    return {
      showApplication: false,
      showLogoMainPage: false,
      showAndroid: false,
      showIos: false,
      application: "",
      logoMainPage: "",
      android: "",
      ios: ""
    };
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);

    this.show = true;
    store
      .dispatch(`${STORE_MODULE_NAME}/getLogoAndIcon`)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false;
        this.application = result.data.application;
        this.logoMainPage = result.data.logoMainPage;
        this.android = result.data.android;
        this.ios = result.data.ios;
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Error"),
            icon: "ErrorIcon",
            variant: "danger",
            text: this.$t(error.response.data.message)
          }
        });
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      });
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
  },
  setup() {
    return { STORE_MODULE_NAME };
  },
  methods: {
    inputImageRenderer(input, type) {
      console.log(input, type);
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          if (type === "application" || type === "logoMainPage") {
            if (input.target.files[0].size > 700000) {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Error"),
                  icon: "ErrorIcon",
                  variant: "danger",
                  text: `${this.$t("Allowed")} JPG, GIF ${this.$t(
                    "or"
                  )} PNG. ${this.$t("Max size of")} 700KB`
                }
              });
              return;
            }
          } else if (input.target.files[0].size > 1000000) {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Error"),
                icon: "ErrorIcon",
                variant: "danger",
                text: `${this.$t("Allowed")} JPG, GIF ${this.$t(
                  "or"
                )} PNG. ${this.$t("Max size of")} 1MB`
              }
            });
            return;
          }
          const image = new Image();
          image.src = e.target.result;
          image.onload = () => {
            const { width, height } = image;

            if (type === "application" || type === "logoMainPage") {
              const obj = { image: e.target.result, type: type };
              if (type === "application") {
                this.showApplication = true;
              } else if (type === "logoMainPage") {
                this.showLogoMainPage = false;
              }
              this.$store
                .dispatch(`${STORE_MODULE_NAME}/logoChange`, obj)
                .then(resp => {
                  console.log(resp);
                  if (type === "application") {
                    this.showApplication = false;

                    this.application = resp.data.data;
                  } else if (type === "logoMainPage") {
                    this.showLogoMainPage = false;

                    this.logoMainPage = resp.data.data;
                  }
                })
                .catch(err => {
                  this.showApplication = false;
                  console.error(err);
                });
            } else if (type === "application") {
              const obj = { image: e.target.result };
              this.showApplication = true;
              this.$store
                .dispatch(`${STORE_MODULE_NAME}/logoChange`, obj)
                .then(resp => {
                  console.log(resp);
                  this.showApplication = false;
                  this.application = resp.data.data;
                })
                .catch(err => {
                  this.showApplication = false;
                  console.error(err);
                });
            } else if (width === 512 && height === 512) {
              if (type === "ios") {
                this.showIos = true;
              } else {
                this.showAndroid = true;
              }
              const obj = { image: e.target.result, type };
              this.$store
                .dispatch(`${STORE_MODULE_NAME}/iconChange`, obj)
                .then(resp => {
                  console.log(resp);
                  if (type === "ios") {
                    this.showIos = false;
                    this.ios = resp.data.data;
                  } else {
                    this.showAndroid = false;
                    this.android = resp.data.data;
                  }
                })
                .catch(err => {
                  if (type === "ios") {
                    this.showIos = false;
                  } else {
                    this.showAndroid = false;
                  }
                  console.error(err);
                });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Error"),
                  icon: "ErrorIcon",
                  variant: "danger",
                  text: "Resolution 512 x 512 pixel"
                }
              });
            }
          };
        };

        reader.readAsDataURL(input.target.files[0]);
      }
    }
  }
};
</script>
