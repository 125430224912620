<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-row>
      <b-col
        md="6"
        lg="4"
      />
      <b-col
        md="6"
        lg="4"
      >
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <b-card
            title="Background Screen"
            class="text-center"
          >
            <b-col class="mb-2">
              <b-img
                v-if="image"
                ref="previewEl"
                rounded
                :src="image"
                height="500"
                width="300"
              />
              <b-img
                v-else
                ref="previewEl"
                :src="require('@/assets/images/blank/no_image.png')"
                rounded
                height="500"
                width="300"
              />
            </b-col>
            <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 700KB</b-card-text>

            <b-form-file
              ref="refInputEl"
              accept=".webp, .jpeg, .jpg, .png, .gif"
              :hidden="true"
              plain
              @change="inputImageRenderer"
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t('Edit') }}
            </b-button>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        md="6"
        lg="4"
      />
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormFile, BCardText, BImg, BOverlay, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

// eslint-disable-next-line no-unused-vars
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/eCommerce/imagesSettings'

const STORE_MODULE_NAME = 'imagesSettings'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BRow, BCol, BCard, BFormFile, BCardText, BImg, BOverlay, BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      image: '',
    }
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getBackgroundScreen`)
    // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        this.image = result.data.data
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 700000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = { image: e.target.result }
          this.show = true
          this.$store
            .dispatch(`${STORE_MODULE_NAME}/backgroundScreenChange`, obj)
            .then(resp => {
              console.log(resp)
              this.show = false
              this.image = resp.data.data.backgroundscreen
            })
            .catch(err => {
              this.show = false
              console.error(err)
            })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
}
</script>
